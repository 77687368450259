<script lang="ts" setup>
import { Switch } from '@gohighlevel/ghl-proposals-common'
import { computed } from 'vue'
import { useAppStore } from '@/store/app'
import { useBuilderStore } from '@/store/builder'

const store = useBuilderStore()
const appStore = useAppStore()

const isInternal = computed(() => appStore.isInternal)

const onDirectPaymentChange = (value: boolean) => {
  appStore.toggleDirectPayment(value)
}
const onAutoSendInvoiceChange = (value: boolean) => {
  appStore.toggleAutoSendInvoice(value)
}
const onPaymentLiveModeChange = (value: boolean) => {
  appStore.togglePaymentLiveMode(value)
}
</script>

<template>
  <div class="relative">
    <Switch
      :value="(appStore.document.enableDirectPayment as boolean)"
      :label="$t('common.enableDirectPayment')"
      :infoLabel="$t('common.enableDirectPaymentDescription')"
      @update:value="onDirectPaymentChange"
      :disabled="!store.isDocumentEditable"
      class="mb-8"
    ></Switch>
  </div>
  <Switch
    :value="(appStore.document.enableAutoSendInvoice as boolean)"
    :label="$t('common.enableSendInvoice')"
    :infoLabel="$t('common.enableSendInvoiceDescription')"
    @update:value="onAutoSendInvoiceChange"
    :disabled="
      appStore.document.enableDirectPayment || !store.isDocumentEditable
    "
  />

  <Switch
    class="mt-8"
    v-if="isInternal"
    :value="(appStore.document.paymentLiveMode as boolean)"
    :label="$t('common.paymentLiveMode')"
    :infoLabel="$t('common.enablePaymentLiveModeDescription')"
    @update:value="onPaymentLiveModeChange"
    :disabled="!store.isDocumentEditable"
  />
</template>
