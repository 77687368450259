<script setup lang="ts">
import { UIDivider } from '@gohighlevel/ghl-ui'
import {
  ScheduleRRuleOptions,
  Switch,
  SchedulingSettings,
  SchedulingSettingsV2,
} from '@gohighlevel/ghl-proposals-common'
import { computed } from 'vue'
import { useAppStore } from '@/store/app'
import { useProductListStore } from '@/store/productListStore'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
})

const appStore = useAppStore()
const productListStore = useProductListStore()

const activeRow = computed(() => productListStore.getActiveRow)

const onUpdate = (value: boolean) => {
  productListStore.toggleGenerateInvoiceOnSigning(value)
}

const onScheduleChange = (
  values: { rrule: ScheduleRRuleOptions },
  validation: {
    errors: Record<string, any>[]
    results: Record<string, any>[]
    valid: boolean
  }
) => {
  productListStore.updateSchedule(values?.rrule)
  productListStore.updateScheduleErrors(validation)
}
</script>

<template>
  <Switch
    class="mt-8"
    :value="(productListStore.generateInvoiceOnSigning as boolean)"
    :label="$t('common.generateInvoiceOnSigning')"
    :infoLabel="$t('common.generateInvoiceOnSigningDescription')"
    @update:value="onUpdate"
    :disabled="props.disabled"
  />

  <SchedulingSettingsV2
    v-if="productListStore.generateInvoiceOnSigning"
    :key="activeRow?.key"
    :rrule="productListStore.schedule"
    :timezone="appStore.timeZone"
    @onScheduleUpdate="onScheduleChange"
    :isInternal="appStore.isInternal"
    :disabled="props.disabled"
  />
  <SchedulingSettings
    v-if="!productListStore.generateInvoiceOnSigning"
    :key="activeRow?.key"
    :rrule="productListStore.schedule"
    :timezone="appStore.timeZone"
    @onScheduleUpdate="onScheduleChange"
    :isInternal="appStore.isInternal"
    :disabled="props.disabled"
  />
  <div class="-mx-6">
    <UIDivider class="mt-6" />
  </div>
</template>
