<script setup lang="ts">
import { UIDatepicker } from '@gohighlevel/ghl-ui'
import {
  IntervalType,
  SCHEDULING_OPTIONS,
  EndType,
  ScheduleRRuleOptions,
} from '../../const'
import * as yup from 'yup'
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'
import { Field } from 'vee-validate'
import { computed, onMounted, PropType, ref } from 'vue'
import {
  getCurrentDate,
  getFormatDate,
  prepareScheduleDetails,
} from '../../utils'
import dayjs from 'dayjs'

const { t } = useI18n()
const emits = defineEmits(['onScheduleUpdate'])
const props = defineProps({
  timezone: {
    type: String,
    required: true,
  },
  rrule: {
    type: Object as PropType<ScheduleRRuleOptions>,
    required: false,
  },
  isInternal: {
    type: Boolean,
    default: false,
  },
  enableByEndType: {
    type: Boolean,
    default: true,
  },
  isForTemplate: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const endDate = ref()
const endDatePickerBorder = ref('1px solid rgb(248 113 113)')

const intervalTypes = computed(() =>
  SCHEDULING_OPTIONS.INTERVAL_TYPE.filter(
    it => !it.internal || (it.internal && props.isInternal)
  )
)

const RRuleSchema = yup.object({
  intervalType: yup
    .mixed()
    .oneOf(Object.values(IntervalType), t('validations.enum.intervalType'))
    .required(t('validations.invalid.intervalType')),

  endType: yup
    .mixed()
    .oneOf(Object.values(EndType), t('validations.enum.endType'))
    .required(t('validations.invalid.endType')),
  endDate: yup.mixed().when(['endType', 'startDate'], (endType, startDate) => {
    if (endType === EndType.BY) {
      return yup
        .date()
        .typeError(t('validations.invalid.dateEndDate'))
        .test('is-greater-than', t('validations.min.endDate'), function (val) {
          const { path, createError } = this
          const currentDate = getCurrentDate()
          const start =
            startDate && startDate !== 'Invalid Date'
              ? getFormatDate(startDate)
              : null

          const isPastCurrantDate = dayjs(val).isBefore(currentDate, 'day')
          const isPastStartDate = start
            ? dayjs(val).isBefore(start, 'day')
            : false

          if (isPastCurrantDate) {
            return createError({
              path,
              message: `${t('validations.min.endDate')} ${getCurrentDate()}`,
            })
          }

          if (isPastStartDate) {
            return createError({
              path,
              message: `${t('validations.min.endDate')} ${getFormatDate(
                startDate
              )}`,
            })
          }

          return true
        })
        .required(t('validations.invalid.endDate'))
    }
  }),
  endTime: yup
    .mixed()
    .when(['endDate', 'intervalType'], (endDate, intervalType) => {
      if (
        [IntervalType.MINUTELY, IntervalType.HOURLY].includes(intervalType) &&
        endDate
      )
        return yup.string().required(t('validations.invalid.endTime'))
    }),
  count: yup.mixed().when('endType', endType => {
    if (endType === EndType.AFTER)
      return yup
        .number()
        .typeError(t('validations.invalid.numberCount'))
        .max(99)
        .nullable()
        .positive(t('validations.invalid.positiveCount'))
        .integer()
        .required(t('validations.invalid.count'))
  }),
  daysBefore: yup
    .mixed()
    .when(
      ['intervalType', 'useStartAsPrimaryUserSigned'],
      (intervalType, useStartAsPrimaryUserSigned) => {
        if (
          !useStartAsPrimaryUserSigned &&
          [
            IntervalType.WEEKLY,
            IntervalType.MONTHLY,
            IntervalType.YEARLY,
          ].includes(intervalType)
        ) {
          const max = intervalType === IntervalType.WEEKLY ? 7 : 28
          return yup
            .number()
            .typeError(t('validations.invalid.numberDaysBefore'))
            .min(0)
            .max(max, `${t('validations.max.daysBefore')} ${max}`)
            .nullable()
            .integer()
            .required(t('validations.invalid.daysBefore'))
        }
      }
    ),
})

const { errors, validate, values, setFieldValue } = useForm({
  validationSchema: RRuleSchema,
  initialValues: {
    intervalType: (props.rrule?.intervalType ||
      IntervalType.WEEKLY) as IntervalType,
    interval: props.rrule?.interval || 0,
    dayOfMonth: props.rrule?.dayOfMonth || '',
    dayOfWeek: props.rrule?.dayOfWeek || '',
    numOfWeek: props.rrule?.numOfWeek || 0,
    monthOfYear: props.rrule?.monthOfYear,
    startTime: props.rrule?.startTime || '',
    endType: props.rrule?.endType || EndType.NEVER,
    endTime: props.rrule?.endTime || '',
    count: props.rrule?.count || '',
    daysBefore: props.rrule?.daysBefore || 0,
    startDate: props.rrule?.startDate || '',
    endDate: props.rrule?.endDate || '',
    useStartAsPrimaryUserSigned:
      props.rrule?.useStartAsPrimaryUserSigned || true,
  },
})

const datePickerBorderColorChange = async () => {
  if (errors.value.endDate) {
    endDatePickerBorder.value = '1px solid rgb(248 113 113)'
  } else {
    endDatePickerBorder.value = '1px solid rgb(224, 224, 230)'
  }
}

const onChangeValues = async () => {
  const validation = await validate()
  const data = {
    ...values,
    endType: values.endType || EndType.NEVER,
    daysBefore: values.daysBefore || 0,
  }
  const formattedSchedule = prepareScheduleDetails(data)
  datePickerBorderColorChange()
  emits('onScheduleUpdate', formattedSchedule, validation)
}

function allowOnlyInteger($event: any) {
  const keyCode = $event.keyCode ? $event.keyCode : $event.which
  const isNumber = keyCode >= 48 && keyCode <= 57
  const newValue = `${$event.target.value}${$event.key}`
  if (!isNumber || Number(newValue) > 99) $event.preventDefault()
}
const onChangeEndDate = (value: string) => {
  setFieldValue('endDate', dayjs(value).format('YYYY-MM-DD'))
  onChangeValues()
}

function endDateDisabled(date: Date) {
  const currentDate = dayjs().format('YYYY-MM-DD')
  const isPastDate = dayjs(date).isBefore(currentDate, 'day')
  const isBeforeStartDate = values.startDate
    ? dayjs(date).isBefore(dayjs(values.startDate).format('YYYY-MM-DD'), 'day')
    : false
  return isPastDate || isBeforeStartDate
}

onMounted(() => {
  if (props.rrule?.endDate) {
    endDate.value = dayjs(props.rrule?.endDate, 'YYYY-MM-DD').valueOf()
  }

  setFieldValue('useStartAsPrimaryUserSigned', true)
  onChangeValues()
})
</script>

<template>
  <div class="py-8 mt-4">
    <label class="text-sm font-normal text-gray-500 my-2"
      >{{ $t('common.setInvoiceFreq') }}
    </label>
    <div class="mb-2">
      <Field
        id="intervalType"
        as="select"
        name="intervalType"
        class="w-full border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm p-2 border mt-2"
        :onchange="onChangeValues"
        :class="{
          'border-red-400': !!errors.intervalType,
        }"
        :disabled="props.disabled"
      >
        <option value="">Select</option>
        <option
          v-for="{ value, label } in intervalTypes"
          :value="value"
          :key="value"
        >
          {{ label }}
        </option>
      </Field>
    </div>
  </div>
  <div class="py-8 mb-8">
    <div class="w-full flex items-flex-start gap-4">
      <div class="w-4/12">
        <div class="mb-1 mt-2">
          <label class="text-sm font-normal text-gray-500">{{
            $t('common.stopSending')
          }}</label>
        </div>
        <Field
          id="endType"
          as="select"
          name="endType"
          :onchange="onChangeValues"
          class="w-full border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm p-2 border mt-2"
          :class="{
            'border-red-400': !!errors.endType,
          }"
          :disabled="props.disabled"
        >
          <option value="">{{ $t('common.select') }}</option>
          <option :value="EndType.NEVER">
            {{ $t('schedulingOptions.never') }}
          </option>
          <option v-if="enableByEndType" :value="EndType.BY">
            {{ $t('schedulingOptions.by') }}
          </option>
          <option :value="EndType.AFTER">
            {{ $t('schedulingOptions.after') }}
          </option>
        </Field>
      </div>
      <div class="w-8/12 flex flex-col">
        <template v-if="values.endType === EndType.BY">
          <div class="mb-1 mt-2">
            <label class="text-sm font-normal text-gray-500">{{
              $t('common.endDate')
            }}</label>
          </div>
          <div>
            <UIDatepicker
              :key="`frequency-date-picker-end-date-picker`"
              @update:value="onChangeEndDate"
              placeholder="End Date"
              class="fq-end-date mt-2 border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm border"
              id="by-date-picker"
              v-model:value="endDate"
              ref="endDatePickerRef"
              :format="'MMMM d, yyyy'"
              :isDateDisabled="endDateDisabled"
              :inputAttributes="{ readonly: true, size: 16 }"
              size="medium"
              :disabled="props.disabled"
            >
            </UIDatepicker>
            <Field type="hidden" name="endDate"></Field>
          </div>

          <div
            v-if="
              [IntervalType.MINUTELY, IntervalType.HOURLY].includes(
                values.intervalType
              )
            "
          >
            <div class="mb-1 mt-2">
              <label class="text-sm font-normal text-gray-500">{{
                $t('common.endTime')
              }}</label>
            </div>

            <Field
              :onchange="onChangeValues"
              id="endTime"
              type="time"
              name="endTime"
              class="w-full border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm border py-2 mt-2"
              :class="{
                'border-red-400': !!errors.endTime,
              }"
              :disabled="props.disabled"
            />
          </div>
        </template>
        <template v-if="values.endType === EndType.AFTER">
          <div class="mb-1 mt-2">
            <label class="text-sm font-normal text-gray-500 capitalize">{{
              $t('schedulingOptions.occurrences')
            }}</label>
          </div>
          <Field
            :onchange="onChangeValues"
            id="count"
            as="input"
            name="count"
            class="w-20 border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm p-2 border mt-2"
            :class="{
              'border-red-400': !!errors.count,
            }"
            type="number"
            @keypress="allowOnlyInteger"
            :disabled="props.disabled"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.n-date-picker.fq-end-date {
  .n-input {
    --n-border: v-bind('endDatePickerBorder') !important;
  }
}
</style>
