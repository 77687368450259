import { PaymentType, ProductType } from '../enums'

import { Options } from '../types'

export const DefaultValidationObjectForDocument = {
  isRecipientsAdded: false,
  isProductListValid: false,
  isNotificationSettingsValid: false,
}

export const DefaultValidationObjectForTemplate = {
  isProductListValid: false,
  isNotificationSettingsValid: false,
}

export const PaymentTypeOptions: Options<PaymentType> = [
  {
    label: 'Cash',
    value: PaymentType.CASH,
  },
  {
    label: 'Cheque',
    value: PaymentType.CHEQUE,
  },
  {
    label: 'Other',
    value: PaymentType.OTHER,
  },
]

export const ProductTypeOptions: Options<ProductType> = [
  {
    label: 'Physical goods',
    value: ProductType.PHYSICAL,
  },
  {
    label: 'Digital goods',
    value: ProductType.DIGITAL,
  },
  {
    label: 'A service',
    value: ProductType.SERVICE,
  },
]
