import config from '@/config'
import { requests } from '.'

const EMAIL_BUILDER_URL = config.EMAIL_BUILDER_SERVICE_URL

export interface EmailTemplateInfo {
  builderVersion: string
  downloadUrl: string
  id: string
  name: string
  updatedBy: string
  _id: string
}

export const EmailBuilderService = {
  getAllEmailTemplates: (params: { locationId: string }) =>
    requests(EMAIL_BUILDER_URL).get<EmailTemplateInfo[]>('/builder/all', {
      params,
    }),
}
