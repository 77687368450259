import {
  EmailBuilderService,
  EmailTemplateInfo,
} from '@/service/EmailBuilderService'

import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

EmailBuilderService

const loading = ref(false)
const error = ref(null)
const emailTemplateList = ref<EmailTemplateInfo[]>([])

export const useEmailTemplates = () => {
  const { t } = useI18n()
  const route = useRoute()
  const fetchEmailTemplates = async () => {
    try {
      loading.value = true
      const response = await EmailBuilderService.getAllEmailTemplates({
        locationId: route.params.locationId as string,
      })
      emailTemplateList.value = [
        {
          value: 'default',
          label: t('common.default'),
          id: 'default',
        } as unknown as EmailTemplateInfo,
        ...response.data.map(emailInfo => {
          return {
            ...emailInfo,
            value: emailInfo.id,
            label: emailInfo.name,
          }
        }),
      ]
    } catch (err) {
      console.error(err)
      error.value = err
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    error,
    emailTemplateList,
    fetchEmailTemplates,
  }
}
