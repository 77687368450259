<script setup lang="ts">
import {
  PaymentSchedule,
  PaymentScheduleItem,
  PaymentScheduleType,
  PaymentDueOn,
  getPaymentScheduleRegularUnits,
  BillingScheduleType,
  ICurrency,
} from '../../types'
import { PropType } from 'vue'
import { UITextSmRegular } from '@gohighlevel/ghl-ui'
import dayjs from 'dayjs'
import currency from 'currency.js'
import { getCurrencyUtil } from '../../utils/currency'

const props = defineProps({
  paymentScheduleInfo: {
    type: Object as PropType<PaymentSchedule> | null | undefined,
    default: null,
  },
  currencyCode: {
    type: String,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
  isOuterBorder: {
    type: Boolean,
    default: false,
  },
  themeType: {
    type: String,
    default: 'horizontal',
  },
  currencyList: {
    type: Object as PropType<{ [key: string]: ICurrency }>,
    default: () => ({}),
  },
})

function getAmountForSchedule(
  schedule: PaymentScheduleItem,
  scheduleType: PaymentScheduleType
) {
  const { formatCurrency, getCurrencySymbol } = getCurrencyUtil(
    props.currencyCode,
    props.currencyList
  )
  if (scheduleType === PaymentScheduleType.PERCENTAGE) {
    const val = currency(props.total).multiply(schedule.value).divide(100).value
    return formatCurrency(val, getCurrencySymbol(props.currencyCode))
  } else {
    return formatCurrency(schedule.value, getCurrencySymbol(props.currencyCode))
  }
}

const dueDateTextUponCompletion = () => {
  const isPlural =
    props.paymentScheduleInfo.billingSchedule?.interval > 1 ? true : false
  const label =
    getPaymentScheduleRegularUnits(isPlural)?.REGULAR_UNITS?.find(
      unit => unit.value === props.paymentScheduleInfo.billingSchedule?.unit
    )?.label || ''
  return `+ ${
    props.paymentScheduleInfo.billingSchedule?.interval || ''
  } ${label}`
}
</script>

<template>
  <div
    class="payment-schedule-summary-section"
    v-if="paymentScheduleInfo"
    :class="isOuterBorder ? 'border-2 rounded p-2' : ''"
  >
    <div v-if="themeType === 'vertical'">
      <div
        v-for="(schedule, idx) in paymentScheduleInfo.schedules"
        class="flex"
        :key="idx"
      >
        <div class="flex flex-col py-2">
          <div>
            <UITextSmRegular>
              {{
                $t('paymentSchedule.scheduleLabel', {
                  number: idx + 1,
                  total: paymentScheduleInfo.schedules.length,
                })
              }}
            </UITextSmRegular>
          </div>

          <div class="flex flex-row">
            <UITextSmRegular class="font-bold mr-3">
              {{ getAmountForSchedule(schedule, paymentScheduleInfo.type) }}
            </UITextSmRegular>

            <UITextSmRegular
              v-if="
                idx === 0 &&
                paymentScheduleInfo?.paymentDueOn ===
                  PaymentDueOn.UPON_DOCUMENT_COMPLETE
              "
              >{{ $t('paymentSchedule.uponCompletion') }}</UITextSmRegular
            >
            <UITextSmRegular
              v-else-if="
                idx === 0 &&
                paymentScheduleInfo?.paymentDueOn === PaymentDueOn.CUSTOM_DATE
              "
            >
              {{
                $t('paymentSchedule.scheduleDue', {
                  dueDate: dayjs(schedule.dueDate).format('MMM D, YYYY'),
                })
              }}</UITextSmRegular
            >
            <UITextSmRegular
              v-else-if="
                idx > 0 &&
                paymentScheduleInfo?.paymentDueOn ===
                  PaymentDueOn.UPON_DOCUMENT_COMPLETE &&
                paymentScheduleInfo?.billingScheduleType ===
                  BillingScheduleType.REGULAR
              "
              >{{ dueDateTextUponCompletion() }}</UITextSmRegular
            >
            <UITextSmRegular v-else-if="idx > 0">
              {{
                $t('paymentSchedule.scheduleDue', {
                  dueDate: dayjs(schedule.dueDate).format('MMM D, YYYY'),
                })
              }}
            </UITextSmRegular>
          </div>
        </div>
      </div>
    </div>

    <div v-if="themeType === 'horizontal'">
      <div
        v-for="(schedule, idx) in paymentScheduleInfo.schedules"
        class="flex gap-2 justify-between py-1"
        :key="idx"
      >
        <UITextSmRegular>
          {{
            $t('paymentSchedule.scheduleLabel', {
              number: idx + 1,
              total: paymentScheduleInfo.schedules.length,
            })
          }}
        </UITextSmRegular>
        <UITextSmRegular
          v-if="
            idx === 0 &&
            paymentScheduleInfo?.paymentDueOn ===
              PaymentDueOn.UPON_DOCUMENT_COMPLETE
          "
          >{{ $t('paymentSchedule.uponCompletion') }}</UITextSmRegular
        >
        <UITextSmRegular
          v-else-if="
            idx === 0 &&
            paymentScheduleInfo?.paymentDueOn === PaymentDueOn.CUSTOM_DATE
          "
        >
          {{
            $t('paymentSchedule.scheduleDue', {
              dueDate: dayjs(schedule.dueDate).format('MMM D, YYYY'),
            })
          }}</UITextSmRegular
        >
        <UITextSmRegular
          v-else-if="
            idx > 0 &&
            paymentScheduleInfo?.paymentDueOn ===
              PaymentDueOn.UPON_DOCUMENT_COMPLETE &&
            paymentScheduleInfo?.billingScheduleType ===
              BillingScheduleType.REGULAR
          "
          >{{ dueDateTextUponCompletion() }}</UITextSmRegular
        >
        <UITextSmRegular v-else-if="idx > 0">
          {{
            $t('paymentSchedule.scheduleDue', {
              dueDate: dayjs(schedule.dueDate).format('MMM D, YYYY'),
            })
          }}
        </UITextSmRegular>

        <UITextSmRegular>
          {{ getAmountForSchedule(schedule, paymentScheduleInfo.type) }}
        </UITextSmRegular>
      </div>
    </div>
  </div>
</template>
