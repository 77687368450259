import {
  DefaultValidationObjectForDocument,
  DiscountType,
  DocumentStatus,
  ELEMENTS_LOOKUP,
  IElement,
  IPricingTable,
  LineItem,
  PriceTypeEnum,
  ProductListElementOptions,
  SentMedium,
  UpdateDocument,
  calculator,
  ccRecipient,
  convertObjToSnakeCase,
  setFillableFieldsDataIntoDocument,
} from '@gohighlevel/ghl-proposals-common'
import { computed, ref } from 'vue'

import { AxiosError } from 'axios'
import { DocumentServices } from '@/service/DocumentService'
import { getTimeZoneAbbreviation } from '@/util'
import { useAppStore } from '@/store/app'
import { useBuilderStore } from '@/store/builder'
import { useCurrency } from './useCurrency'
import { useGroups } from './useGroups'
import { useLineItems } from './useLineItems'
import { useNotify } from './useNotify'
import { usePostmate } from './usePostmate'
import { useProductListStore } from '@/store/productListStore'
import { useRoute } from 'vue-router'

const loading = ref(false)
const loadingCustomValues = ref(false)
const documentMetaInfo = ref({})
export const useDocument = () => {
  const route = useRoute()
  const appStore = useAppStore()
  const builderStore = useBuilderStore()
  const productListStore = useProductListStore()
  const recipients = computed(() => appStore.document.recipients)
  const { getCurrency } = useCurrency()
  const { handleError, handleSuccess } = useNotify()
  const { redirectOnParentRoute } = usePostmate()
  const { setGroupTargetsOnSelection } = useGroups()

  const validateDocument = () => {
    const errors = DefaultValidationObjectForDocument
    const productListErrors = productListStore.getProductListErrors()
    errors.isProductListValid =
      productListErrors && productListErrors.length > 0 ? false : true

    if (recipients.value.length > 0) {
      const primaryRecipient = recipients.value.find(
        (el: any) => el.isPrimary === true
      )
      errors.isRecipientsAdded = primaryRecipient ? true : false
    } else {
      errors.isRecipientsAdded = false
    }

    const settingsErrors = appStore.getSettingsErrors()
    errors.isNotificationSettingsValid =
      settingsErrors && settingsErrors.length > 0 ? false : true

    return errors
  }

  const isDocumentValid = () => {
    const validations = validateDocument()
    return Object.values(validations).every(val => val === true)
  }

  const loadDocument = async () => {
    try {
      loading.value = true
      const { data } = await DocumentServices.getDocument({
        locationId: route.params.locationId as string,
        id: route.params.documentId as string,
      })
      const pages = setFillableFieldsDataIntoDocument(
        data.document.pages,
        data.document.fillableFields,
        data.document.pricingTables
      )
      appStore.setDocument({ ...data.document, pages })
      if (data?.links) {
        appStore.setLinkReference(data?.links)
      }
      if (data?.whiteLabelBaseUrl) {
        appStore.setWhiteLabelViewerBaseUrl(data?.whiteLabelBaseUrl)
      }
      if (data?.invoiceWhiteLabelBaseUrl) {
        appStore.setInvoiceWhiteLabelBaseUrl(data?.invoiceWhiteLabelBaseUrl)
      }
      const updatedPages = pages
      if (updatedPages && updatedPages.length)
        builderStore.setPages(updatedPages)
      else builderStore.addPage()
      builderStore.setStatus(data.document.status)

      const groups =
        data?.document?.groups && data?.document?.groups.length
          ? data?.document?.groups
          : []
      builderStore.setGroups(groups)

      if (data?.paymentInfo) {
        const paymentInfo = data.paymentInfo
        if (paymentInfo?.paymentSettings?.schedule?.rrule) {
          productListStore.setSchedule(
            paymentInfo?.paymentSettings?.schedule?.rrule
          )
          productListStore.setInvoiceType(paymentInfo?.invoiceType)
        }
        if (paymentInfo?.invoiceId) {
          productListStore.setInvoiceId(paymentInfo?.invoiceId)
        }
        if (paymentInfo?.scheduleId) {
          productListStore.setScheduleId(paymentInfo?.scheduleId)
        }
        productListStore.toggleGenerateInvoiceOnSigning(
          paymentInfo?.generateInvoiceOnSigning as boolean
        )
      }

      const customValueLinkage = data?.document?.customValueLinkage
        ? data?.document?.customValueLinkage
        : {}
      builderStore.setCustomValueLinkage(customValueLinkage)
    } catch (error) {
      console.error(error)
      handleError(error as AxiosError)
    } finally {
      loading.value = false
    }
  }

  const totalDueAmountOfAllProducts = computed(() => {
    return Number(
      builderStore.pages
        .reduce((acc, page) => {
          page.children?.forEach(child => {
            if (child.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
              const { amountDue } = useLineItems(child.id)
              acc += amountDue.value
            }
            if (child.type === ELEMENTS_LOOKUP.ROW) {
              child?.children?.forEach(column => {
                column?.children?.forEach(child => {
                  if (child.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
                    const { amountDue } = useLineItems(child.id)
                    acc += amountDue.value
                  }
                })
              })
            }
          })
          return acc
        }, 0)
        ?.toFixed(2)
    )
  })

  const subTotalAcrossProductTables = computed(() => {
    return Number(
      builderStore.pages
        .reduce((acc, page) => {
          page.children?.forEach(child => {
            if (child.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
              const { subTotal } = useLineItems(child.id)
              acc += subTotal.value
            }
            if (child.type === ELEMENTS_LOOKUP.ROW) {
              child?.children?.forEach(column => {
                column?.children?.forEach(child => {
                  if (child.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
                    const { subTotal } = useLineItems(child.id)
                    acc += subTotal.value
                  }
                })
              })
            }
          })
          return acc
        }, 0)
        ?.toFixed(2)
    )
  })

  const totalDiscountInPercentage = computed(() => {
    // check how many product list has for the document.
    // This is added because of the miscalculation while getting the percentage
    // if it has only one product list and the discount type is in percentage take that value.
    const productLists = builderStore.getAllProductList()
    if (productLists && productLists.length === 1) {
      if (
        productLists[0].component?.options?.discountType ===
        DiscountType.PERCENTAGE
      ) {
        return productLists[0].component?.options?.discountValue
      }
    }

    // otherwise go ahead with the reduce operation.
    let finalProductListVersion = 1
    const totalDiscountAcrossTables = productLists.reduce(
      (acc, productList) => {
        const { totalDiscount, productListVersion } = useLineItems(
          productList.id
        )
        finalProductListVersion = productListVersion.value
        acc += totalDiscount.value
        return acc
      },
      0
    )
    const { getPercentage } = calculator(
      Number(subTotalAcrossProductTables.value),
      finalProductListVersion
    )
    return getPercentage(totalDiscountAcrossTables, finalProductListVersion)
  })

  const saveDocument = async () => {
    if (!appStore.unsavedChanges) return

    try {
      loading.value = true
      const fillableFields = builderStore.getFillableFields()

      // if any one recurring product line item found, then the invoice should be recurring invoice
      const invoiceType = productListStore.invoiceType
      const schedule =
        invoiceType === PriceTypeEnum.RECURRING
          ? { rrule: productListStore.schedule }
          : {}
      const paymentInfo = {
        invoiceType,
        schedule,
        generateInvoiceOnSigning: productListStore.generateInvoiceOnSigning,
      }

      if (appStore?.document?.notificationSettings?.value) {
        delete appStore?.document?.notificationSettings?.value
      }

      const { data } = await DocumentServices.updateDocument({
        id: route.params.documentId as string,
        name: appStore.document?.name?.trim(),
        locationId: route.params.locationId as string,
        pages: builderStore.pages,
        groups: builderStore.groups,
        variables: appStore.document?.variables,
        recipients: appStore.document?.recipients,
        timezone: {
          zone: appStore.timeZone,
          abbreviation: getTimeZoneAbbreviation(appStore.timeZone),
        },
        grandTotal: {
          currency: productListStore.currency || getCurrency(),
          amount: totalDueAmountOfAllProducts.value || 0,
          discountPercentage: totalDiscountInPercentage.value,
          discounts: builderStore.pages.reduce((acc, page: IElement) => {
            page.children?.forEach(children => {
              if (
                children.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
                children.component.options.lineItems &&
                children.component.options.lineItems.length > 0 &&
                children.component.options.discountValue > 0
              ) {
                acc.push({
                  id: children.id,
                  value: children.component.options.discountValue,
                  type: children.component.options.discountType,
                })
              }
              if (children.type === ELEMENTS_LOOKUP.ROW) {
                children?.children?.forEach(column => {
                  column?.children?.forEach(child => {
                    if (
                      child.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
                      child.component.options.lineItems &&
                      child.component.options.lineItems.length > 0 &&
                      child.component.options.discountValue > 0
                    ) {
                      acc.push({
                        id: child.id,
                        value: child.component.options.discountValue,
                        type: child.component.options.discountType,
                      })
                    }
                  })
                })
              }
            })
            return acc
          }, [] as Record<string, any>[]),
        },
        fillableFields: fillableFields,
        enableSigningOrder: appStore.document.enableSigningOrder,
        pricingTables: builderStore.pages.reduce((acc, page) => {
          page?.children?.forEach((child: IElement) => {
            if (
              child.version > 1 &&
              child.type === ELEMENTS_LOOKUP.PRODUCT_LIST
            ) {
              acc.push({
                version: child.version,
                id: child.id,
                lineItems: (child as IElement<ProductListElementOptions>)
                  .component.options.lineItems as LineItem[],
              })
            }
            if (child.type === ELEMENTS_LOOKUP.ROW) {
              child?.children?.forEach(column => {
                column?.children?.forEach(child => {
                  if (
                    child.version > 1 &&
                    child.type === ELEMENTS_LOOKUP.PRODUCT_LIST
                  ) {
                    acc.push({
                      version: child.version,
                      id: child.id,
                      lineItems: (child as IElement<ProductListElementOptions>)
                        .component.options.lineItems as LineItem[],
                    })
                  }
                })
              })
            }
          })
          return acc
        }, [] as IPricingTable[]),
        paymentInfo,
        enableAutoSendInvoice: appStore.document.enableAutoSendInvoice,
        enableDirectPayment: appStore.document.enableDirectPayment,
        paymentLiveMode: appStore?.document?.paymentLiveMode ?? true,
        customValueLinkage: builderStore.customValueLinkage,
        ccRecipients:
          appStore.document?.ccRecipients?.map(({ _id, ...rest }) => rest) ||
          [],
        notificationSettings: appStore?.document?.notificationSettings ?? {},
      } as unknown as UpdateDocument)
      appStore.setDocument(data.document)
      handleSuccess('Document saved successfully !!')
    } catch (error) {
      console.error(error)
      handleError(error as AxiosError)
    } finally {
      loading.value = false
      appStore.setUnsavedChanges(false)
    }
  }

  const sendDocument = async (
    medium: SentMedium,
    params?: { name?: string; ccRecipients: ccRecipient[] }
  ) => {
    try {
      loading.value = true
      const body = {
        documentId: route.params.documentId as string,
        locationId: route.params.locationId as string,
        medium,
        ...(params?.name && { documentName: params.name }),
        ...(params?.ccRecipients && { ccRecipients: params.ccRecipients }),
      }
      const { data } = await DocumentServices.sendDocument(body)
      if (data?.links) {
        appStore.setLinkReference(data?.links)
      }
      builderStore.updateActiveElement(null)
      setGroupTargetsOnSelection([])
      appStore.document.status = DocumentStatus.SENT
      builderStore.setStatus(DocumentStatus.SENT)
      const message =
        medium === SentMedium.LINK
          ? 'Link generated successfully!!'
          : 'Email sent successfully!!'
      handleSuccess(message)
    } catch (error) {
      console.error(error)
      handleError(error as AxiosError)
    } finally {
      loading.value = false
    }
  }

  const updateDocumentStatus = async (status: DocumentStatus) => {
    try {
      loading.value = true
      const { data } = await DocumentServices.updateDocumentStatus({
        documentId: route.params.documentId as string,
        status,
      })
      return data
    } catch (err) {
      console.error(err)
      handleError(err as AxiosError)
      throw err
    } finally {
      loading.value = false
    }
  }

  const convertToDraft = () => {
    return updateDocumentStatus(DocumentStatus.DRAFT).then(async () => {
      await loadDocument()
      builderStore.updateActiveElement(null)
      setGroupTargetsOnSelection([])
      appStore.document.status = DocumentStatus.DRAFT
      builderStore.setStatus(DocumentStatus.DRAFT)
      await fetchAllCustomValues()
    })
  }

  const markAsAccepted = () => {
    return updateDocumentStatus(DocumentStatus.ACCEPTED).then(() => {
      appStore.document.status = DocumentStatus.ACCEPTED
      builderStore.setStatus(DocumentStatus.ACCEPTED)
    })
  }

  const markAsCompleted = () => {
    return updateDocumentStatus(DocumentStatus.COMPLETED).then(async () => {
      await loadDocument()
      builderStore.updateActiveElement(null)
      setGroupTargetsOnSelection([])
      appStore.document.status = DocumentStatus.COMPLETED
      builderStore.setStatus(DocumentStatus.COMPLETED)
      await fetchAllCustomValues()
    })
  }

  const removeDocument = async () => {
    try {
      loading.value = true
      const { data } = await DocumentServices.removeDocument(
        route.params.documentId as string
      )
      return data?.document
    } catch (error) {
      console.error(error)
      handleError(error as AxiosError)
    } finally {
      loading.value = false
    }
  }

  const downloadPdf = async () => {
    try {
      loading.value = true
      const { data } = await DocumentServices.downloadPdf({
        documentId: route.params.documentId as string,
        altType: 'location',
        altId: route.params.locationId as string,
      })
      return data
    } catch (err) {
      console.error(err)
      handleError(err as AxiosError)
    } finally {
      loading.value = false
    }
  }

  const pushToListPage = () => {
    redirectOnParentRoute({
      name: 'proposals-estimates',
      params: {
        locationId: route.params.locationId as string,
      },
    })
  }

  const saveAsTemplate = async () => {
    try {
      loading.value = true
      const { data } = await DocumentServices.saveAsTemplate({
        locationId: route.params.locationId as string,
        documentId: route.params.documentId as string,
      })
      redirectOnParentRoute({
        name: 'proposals-estimates-templates',
        params: {
          id: data.id,
          locationId: route.params.locationId as string,
        },
      })
    } catch (error) {
      console.error(error)
      handleError(error as AxiosError)
    } finally {
      loading.value = false
      appStore.setUnsavedChanges(false)
    }
  }

  const fetchAllCustomValues = async () => {
    const primaryContact = appStore.primaryContact
    try {
      loadingCustomValues.value = true
      const { data } = await DocumentServices.getAllCustomVariables(
        route.params.locationId as string,
        route.params.documentId as string,
        primaryContact?.id
      )
      builderStore.setFlatCustomValues(
        convertObjToSnakeCase(data, ['document'])
      )
    } catch (error) {
      console.error(error)
      handleError(error as AxiosError)
    } finally {
      loadingCustomValues.value = false
    }
  }

  const getDocumentMeta = async () => {
    try {
      loading.value = true
      const { data } = await DocumentServices.getDocumentMetaInfo({
        documentId: route.params.documentId as string,
        locationId: route.params.locationId as string,
      })
      if (data) documentMetaInfo.value = data
      return data
    } catch (error) {
      console.error(error)
      handleError(error as AxiosError)
    } finally {
      loading.value = false
    }
  }
  return {
    loading,
    saveDocument,
    validateDocument,
    convertToDraft,
    markAsAccepted,
    markAsCompleted,
    loadDocument,
    removeDocument,
    pushToListPage,
    downloadPdf,
    saveAsTemplate,
    sendDocument,
    isDocumentValid,
    fetchAllCustomValues,
    loadingCustomValues,
    getDocumentMeta,
    documentMetaInfo,
  }
}
