<script setup lang="ts">
import { UISwitch, UITextSmMedium, UITextSmRegular } from '@gohighlevel/ghl-ui'
const props = defineProps({
  value: {
    type: Boolean,
    required: true,
  },
  class: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
  infoLabel: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['update:value'])
const onEnableSigningOrder = (value: boolean) => {
  emit('update:value', value)
}
</script>

<template>
  <div class="flex items-start gap-2 mb-2" :class="props.class">
    <UISwitch
      :value="props.value"
      @update:value="onEnableSigningOrder"
      :disabled="props.disabled"
    />
    <div>
      <UITextSmMedium class="text-gray-900">{{ props.label }}</UITextSmMedium>
      <UITextSmRegular class="text-gray-700" v-if="props.infoLabel">{{
        props.infoLabel
      }}</UITextSmRegular>
    </div>
  </div>
</template>
