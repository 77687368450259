import i18n from '../locales'

export interface ScheduleRRuleOptions {
  intervalType: IntervalType | string
  interval: number
  startDate: string
  startTime?: string
  endDate?: string
  endTime?: string
  dayOfMonth?: number | string
  dayOfWeek?: ShortWeekDays | string
  numOfWeek?: number
  monthOfYear?: ShortMonths | string
  count?: number
  daysBefore?: number
  endType?: string
  useStartAsPrimaryUserSigned?: boolean
}

export enum EndType {
  NEVER = 'never',
  BY = 'by',
  AFTER = 'after',
}

export enum IntervalType {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  DAILY = 'daily',
  HOURLY = 'hourly',
  MINUTELY = 'minutely',
}

export enum PriceItemIntervalType {
  'month' = IntervalType.MONTHLY,
  'year' = IntervalType.YEARLY,
  'week' = IntervalType.WEEKLY,
  'day' = IntervalType.DAILY,
  'hour' = IntervalType.HOURLY,
}

export enum ShortMonths {
  JAN = 'jan',
  FEB = 'feb',
  MAR = 'mar',
  APR = 'apr',
  MAY = 'may',
  JUN = 'jun',
  JUL = 'jul',
  AUG = 'aug',
  SEP = 'sep',
  OCT = 'oct',
  NOV = 'nov',
  DEC = 'dec',
}

export enum ShortWeekDays {
  MO = 'mo',
  TU = 'tu',
  WE = 'we',
  TH = 'th',
  FR = 'fr',
  SA = 'sa',
  SU = 'su',
}

export const MONTH_NAMES = {
  jan: 0,
  feb: 1,
  mar: 2,
  apr: 3,
  may: 4,
  jun: 5,
  jul: 6,
  aug: 7,
  sep: 8,
  oct: 9,
  nov: 10,
  dec: 11,
}

export const DAY_NAMES = {
  su: 0, // Sunday
  mo: 1, // Monday
  tu: 2, // Tuesday
  we: 3, // Wednesday
  th: 4, // Thursday
  fr: 5, // Friday
  sa: 6, // Saturday
}

export const SCHEDULING_OPTIONS = {
  INTERVAL_TYPE: [
    {
      value: IntervalType.MINUTELY,
      label: i18n.global.t('schedulingOptions.intervalType.minutely'),
      internal: true,
    },
    {
      value: IntervalType.HOURLY,
      label: i18n.global.t('schedulingOptions.intervalType.hourly'),
      internal: true,
    },
    {
      value: IntervalType.DAILY,
      label: i18n.global.t('schedulingOptions.intervalType.daily'),
    },
    {
      value: IntervalType.WEEKLY,
      label: i18n.global.t('schedulingOptions.intervalType.weekly'),
    },
    {
      value: IntervalType.MONTHLY,
      label: i18n.global.t('schedulingOptions.intervalType.monthly'),
    },
    {
      value: IntervalType.YEARLY,
      label: i18n.global.t('schedulingOptions.intervalType.yearly'),
    },
  ],
  WEEKDAYS: [
    {
      value: ShortWeekDays.MO,
      label: i18n.global.t('schedulingOptions.weekdays.monday'),
    },
    {
      value: ShortWeekDays.TU,
      label: i18n.global.t('schedulingOptions.weekdays.tuesday'),
    },
    {
      value: ShortWeekDays.WE,
      label: i18n.global.t('schedulingOptions.weekdays.wednesday'),
    },
    {
      value: ShortWeekDays.TH,
      label: i18n.global.t('schedulingOptions.weekdays.thursday'),
    },
    {
      value: ShortWeekDays.FR,
      label: i18n.global.t('schedulingOptions.weekdays.friday'),
    },
    {
      value: ShortWeekDays.SA,
      label: i18n.global.t('schedulingOptions.weekdays.saturday'),
    },
    {
      value: ShortWeekDays.SU,
      label: i18n.global.t('schedulingOptions.weekdays.sunday'),
    },
  ],
  DAYS: [
    {
      value: 1,
      label: i18n.global.t('schedulingOptions.days.1'),
    },
    {
      value: 2,
      label: i18n.global.t('schedulingOptions.days.2'),
    },
    {
      value: 3,
      label: i18n.global.t('schedulingOptions.days.3'),
    },
    {
      value: 4,
      label: i18n.global.t('schedulingOptions.days.4'),
    },
    {
      value: 5,
      label: i18n.global.t('schedulingOptions.days.5'),
    },
    {
      value: 6,
      label: i18n.global.t('schedulingOptions.days.6'),
    },
    {
      value: 7,
      label: i18n.global.t('schedulingOptions.days.7'),
    },
    {
      value: 8,
      label: i18n.global.t('schedulingOptions.days.8'),
    },
    {
      value: 9,
      label: i18n.global.t('schedulingOptions.days.9'),
    },
    {
      value: 10,
      label: i18n.global.t('schedulingOptions.days.10'),
    },
    {
      value: 11,
      label: i18n.global.t('schedulingOptions.days.11'),
    },
    {
      value: 12,
      label: i18n.global.t('schedulingOptions.days.12'),
    },
    {
      value: 13,
      label: i18n.global.t('schedulingOptions.days.13'),
    },
    {
      value: 14,
      label: i18n.global.t('schedulingOptions.days.14'),
    },
    {
      value: 15,
      label: i18n.global.t('schedulingOptions.days.15'),
    },
    {
      value: 16,
      label: i18n.global.t('schedulingOptions.days.16'),
    },
    {
      value: 17,
      label: i18n.global.t('schedulingOptions.days.17'),
    },
    {
      value: 18,
      label: i18n.global.t('schedulingOptions.days.18'),
    },
    {
      value: 19,
      label: i18n.global.t('schedulingOptions.days.19'),
    },
    {
      value: 20,
      label: i18n.global.t('schedulingOptions.days.20'),
    },
    {
      value: 21,
      label: i18n.global.t('schedulingOptions.days.21'),
    },
    {
      value: 22,
      label: i18n.global.t('schedulingOptions.days.22'),
    },
    {
      value: 23,
      label: i18n.global.t('schedulingOptions.days.23'),
    },
    {
      value: 24,
      label: i18n.global.t('schedulingOptions.days.24'),
    },
    {
      value: 25,
      label: i18n.global.t('schedulingOptions.days.25'),
    },
    {
      value: 26,
      label: i18n.global.t('schedulingOptions.days.26'),
    },
    {
      value: 27,
      label: i18n.global.t('schedulingOptions.days.27'),
    },
    {
      value: 28,
      label: i18n.global.t('schedulingOptions.days.28'),
    },
    {
      value: -1,
      label: i18n.global.t('schedulingOptions.days.last'),
    },
  ],
  MONTHS: [
    {
      value: ShortMonths.JAN,
      label: i18n.global.t('schedulingOptions.months.january'),
    },
    {
      value: ShortMonths.FEB,
      label: i18n.global.t('schedulingOptions.months.february'),
    },
    {
      value: ShortMonths.MAR,
      label: i18n.global.t('schedulingOptions.months.march'),
    },
    {
      value: ShortMonths.APR,
      label: i18n.global.t('schedulingOptions.months.april'),
    },
    {
      value: ShortMonths.MAY,
      label: i18n.global.t('schedulingOptions.months.may'),
    },
    {
      value: ShortMonths.JUN,
      label: i18n.global.t('schedulingOptions.months.june'),
    },
    {
      value: ShortMonths.JUL,
      label: i18n.global.t('schedulingOptions.months.july'),
    },
    {
      value: ShortMonths.AUG,
      label: i18n.global.t('schedulingOptions.months.august'),
    },
    {
      value: ShortMonths.SEP,
      label: i18n.global.t('schedulingOptions.months.september'),
    },
    {
      value: ShortMonths.OCT,
      label: i18n.global.t('schedulingOptions.months.october'),
    },
    {
      value: ShortMonths.NOV,
      label: i18n.global.t('schedulingOptions.months.november'),
    },
    {
      value: ShortMonths.DEC,
      label: i18n.global.t('schedulingOptions.months.december'),
    },
  ],
}
