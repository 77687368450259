import { useNotification } from '@gohighlevel/ghl-ui'
import { useInfiniteQuery } from '@tanstack/vue-query'
import { debounce, unionBy } from 'lodash'
import { SelectOption } from 'naive-ui'
import { Ref, computed, ref, watch } from 'vue'
import ContactService, {
  ContactSearchResponse,
} from '../services/ContactService'

const notification = useNotification()

export function useContactSearchForEmail(
  args: { enabled: Ref<boolean> },
  locationId: string
) {
  const { enabled } = args || {}

  const PAGE_LIMIT = 20
  const currentPageNumber = ref(1)

  async function searchLogic(
    text: string,
    pageNumber = 1,
    signal?: AbortSignal
  ) {
    const reqBody = {
      locationId,
      page: pageNumber,
      filters: [
        {
          id: 'conversations',
          filterName: 'generic',
          filterName_lc: 'generic',
          selectedOption: {
            filterName: 'generic',
            filterName_lc: 'generic',
            condition: 'is',
            firstValue: text,
          },
        },
      ],
      sort: [],
      pageLimit: PAGE_LIMIT,
      justIds: false,
    }
    return await ContactService.searchContacts(reqBody, signal)
  }

  const searchText = ref('')

  const { data, isFetching, fetchNextPage, isError, error } = useInfiniteQuery({
    queryKey: ['search-contacts', locationId, searchText],
    queryFn: async ({ pageParam, signal }) => {
      currentPageNumber.value = pageParam
      const res = await searchLogic(searchText.value, pageParam, signal)
      return res.data
    },
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      const nextPage = currentPageNumber.value + 1
      const totalCount = lastPage?.total ?? 0
      const totalPages = Math.ceil(totalCount / PAGE_LIMIT)
      if (nextPage > totalPages) return null
      return nextPage
    },
    enabled,
    staleTime: 15000,
    refetchOnWindowFocus: false,
  })

  const clearQuery = () => {
    searchText.value = ''
  }

  const search = debounce((text: string) => {
    searchText.value = text
  }, 300)

  const contactList = computed(() => {
    const options = data.value?.pages?.flatMap(page => {
      return convertIntoOptions(page)
    })
    return unionBy(options, 'value')
  })

  watch([isError], () => {
    if (error.value) return
    notification.error({
      title:
        error?.value?.message ||
        'Something went wrong while fetching contacts.',
      keepAliveOnHover: true,
      duration: 4000,
    })
  })

  return {
    contactList,
    search,
    loading: isFetching,
    clearQuery,
    fetchNextPage,
    getContactInfoForId,
  }
}

function convertIntoOptions(
  contactSearchRes: ContactSearchResponse
): SelectOption[] {
  const contacts = contactSearchRes.contacts

  if (!contacts.length) return []

  const options: SelectOption[] = []
  contacts.forEach(contact => {
    if (!contact.email) return
    if (contact?.additionalEmails?.length) {
      const data = getOptionsItem(contact)
      options.push(data)
      contact.additionalEmails.forEach(({ email }) => {
        const data = getOptionsItem(
          contact,
          getLabel(contact.contactName, email)
        )
        options.push(data)
      })
    } else {
      const data = getOptionsItem(contact)
      options.push(data)
    }
  })
  return options
}

export function getLabel(name: string, email: string) {
  return name ? `${name} <${email}>` : email
}

function getContactInfoForId(id: string, contactList?: any) {
  if (!contactList) return null
  const contact = contactList.find((contact: any) => contact.value === id)
  return contact
}

export function getOptionsItem(contact: any, label?: string) {
  const profilePhoto = contact.profilePhoto
  return {
    email: contact.email,
    label: label ? label : getLabel(contact.contactName, contact.email),
    value: `${contact.id}:${contact.email}`,
    imageUrl: profilePhoto,
    id: contact.id,
    contactName: contact.contactName,
    firstName: contact.firstName,
    lastName: contact.lastName,
  }
}
