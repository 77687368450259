import {
  CurrencyDetails,
  CurrencyOption,
  ELEMENTS_LOOKUP,
  LineItem,
  PriceTypeEnum,
  ScheduleRRuleOptions,
} from '@gohighlevel/ghl-proposals-common'

import { useLineItems } from '@/composition'
import i18n from '@gohighlevel/ghl-proposals-common/src/locales'
import {
  PaymentSchedule,
  PaymentScheduleType,
} from '@gohighlevel/ghl-proposals-common/src/types'
import { defineStore } from 'pinia'
import { useAppStore } from './app'
import { useBuilderStore } from './builder'

interface ScheduleValidation {
  errors: Record<string, any>[]
  results: Record<string, any>[]
  valid: boolean
}
interface ProductListStore {
  selectedRows: string[]
  activeRow: LineItem | null
  currency: string
  currencyDetails: CurrencyDetails
  currencyOptions: CurrencyOption[]
  activeListId: string | null
  schedule: ScheduleRRuleOptions
  invoiceType: PriceTypeEnum
  scheduleValidation: ScheduleValidation
  invoiceId: string
  invoiceScheduleId: string
  generateInvoiceOnSigning: boolean
  paymentDepositSchedule: PaymentSchedule
  enableAutoPayment: boolean
}
export const useProductListStore = defineStore('productListStore', {
  state: (): ProductListStore => ({
    activeListId: null,
    selectedRows: [],
    activeRow: null,
    currency: '',
    currencyDetails: {} as CurrencyDetails,
    currencyOptions: [],
    schedule: {} as ScheduleRRuleOptions, // schedule for the recurring invoice
    invoiceType: PriceTypeEnum.ONETIME,
    scheduleValidation: {} as ScheduleValidation,
    invoiceId: '',
    invoiceScheduleId: '',
    generateInvoiceOnSigning: true,
    paymentDepositSchedule: {} as PaymentSchedule, // schedule for the deposit payment [ applicable for one time payment]
    enableAutoPayment: false,
  }),
  getters: {
    getActiveRow: state => state.activeRow,
  },
  actions: {
    setSelectedRows(rows: string[]) {
      this.selectedRows = rows
    },
    setActiveRow(
      lineItem: LineItem | null | undefined,
      tableId: string | null
    ) {
      if (lineItem) {
        this.activeRow = Object.assign({}, lineItem)
      } else {
        this.activeRow = Object.assign({}, null)
      }
      this.activeListId = tableId
    },
    updateActiveRow(options: {
      key: keyof LineItem
      value: string | number | undefined
    }) {
      if (this.activeRow) {
        this.activeRow = { ...this.activeRow, ...options }
      }
    },
    setProductListCurrency(currency: string) {
      this.currency = currency
    },
    setCurrencyDetails(currencyData: CurrencyDetails) {
      this.currencyDetails = currencyData
      this.currencyOptions = Object.entries(currencyData.currency).map(
        ([currencyCode, currencyData]: any) => {
          return { label: currencyData.code, value: currencyCode }
        }
      )
    },
    updateSchedule(values: ScheduleRRuleOptions) {
      const appStore = useAppStore()
      appStore.setUnsavedChanges(true)
      this.schedule = values
    },
    setSchedule(rrule: ScheduleRRuleOptions) {
      this.schedule = rrule
    },
    setPaymentDepositSchedule(schedule: PaymentSchedule) {
      const appStore = useAppStore()
      this.paymentDepositSchedule = schedule
      // if schedule is not empty, then make all the line items optional & qtyEditable as false
      if (schedule && Object.keys(schedule).length > 0) {
        const builderStore = useBuilderStore()
        builderStore.setAllLineItemsAndQtyOptional(false)
        this.enableAutoPaymentOnChangeInvoiceType()
      }
      appStore.setUnsavedChanges(true)
    },
    removePaymentDepositSchedule() {
      const appStore = useAppStore()
      this.paymentDepositSchedule = {} as PaymentSchedule
      appStore.setUnsavedChanges(true)
    },
    setInvoiceType(invoiceType: PriceTypeEnum) {
      const appStore = useAppStore()
      this.invoiceType = invoiceType
      this.enableAutoPaymentOnChangeInvoiceType()
      appStore.setUnsavedChanges(true)
    },
    updateScheduleErrors(validation: ScheduleValidation) {
      this.scheduleValidation = validation
    },
    setInvoiceId(invoiceId: string) {
      this.invoiceId = invoiceId
    },
    setScheduleId(scheduleId: string) {
      this.invoiceScheduleId = scheduleId
    },
    getProductListErrors() {
      const errors: string[] = []
      const builderStore = useBuilderStore()
      const productLists = builderStore.getAllProductList()
      if (productLists && productLists.length > 0) {
        if (this.invoiceType === PriceTypeEnum.RECURRING) {
          const scheduleKeys = Object.keys(this.schedule)
          if (scheduleKeys.length === 0) {
            errors.push(i18n.global.t('validations.required.schedule'))
          }
          if (
            scheduleKeys.length > 0 &&
            this.scheduleValidation &&
            !this.scheduleValidation?.valid &&
            this.scheduleValidation?.errors
          ) {
            const result = Object.values(this.scheduleValidation?.errors)
            return [...errors, ...result]
          }
        }

        if (this.invoiceType === PriceTypeEnum.ONETIME) {
          const depositScheduleKeys = Object.keys(this.paymentDepositSchedule)
          if (this.paymentDepositSchedule && depositScheduleKeys.length > 0) {
            if (
              this.paymentDepositSchedule.type === PaymentScheduleType.FIXED
            ) {
              const totalScheduleValue =
                this.paymentDepositSchedule.schedules.reduce(
                  (sum, item) => sum + item.value,
                  0
                )
              const grandTotal = this.getGrandTotalForProductList()
              if (
                grandTotal != totalScheduleValue ||
                totalScheduleValue === 0 ||
                grandTotal === 0
              ) {
                errors.push(
                  i18n.global.t(
                    'validations.invalid.paymentDepositScheduleTotalValue'
                  )
                )
              }
            }
          }
        }
      }
      return errors
    },
    updateInvoiceTypeIfRecurringProductExist() {
      const builderStore = useBuilderStore()
      const recurringProduct = builderStore.isRecurringProduct()
      if (recurringProduct) {
        this.setInvoiceType(PriceTypeEnum.RECURRING)
        this.updateSchedule({
          intervalType: recurringProduct?.recurring?.interval,
          interval: recurringProduct?.recurring?.intervalCount,
        } as ScheduleRRuleOptions)
      } else {
        this.setInvoiceType(PriceTypeEnum.ONETIME)
      }
    },
    resetSchedule() {
      this.schedule = {} as ScheduleRRuleOptions
      this.invoiceType = PriceTypeEnum.ONETIME
    },
    toggleGenerateInvoiceOnSigning(value: boolean) {
      this.generateInvoiceOnSigning = value
    },
    toggleEnableAutoPayment(value: boolean) {
      const appStore = useAppStore()
      this.enableAutoPayment = value
      appStore.setUnsavedChanges(true)
    },
    getGrandTotalForProductList() {
      const builderStore = useBuilderStore()
      return Number(
        builderStore.pages
          .reduce((acc, page) => {
            page.children?.forEach(child => {
              if (child.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
                const { amountDue } = useLineItems(child.id)
                acc += amountDue.value
              }
              if (child.type === ELEMENTS_LOOKUP.ROW) {
                child?.children?.forEach(column => {
                  column?.children?.forEach(child => {
                    if (child.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
                      const { amountDue } = useLineItems(child.id)
                      acc += amountDue.value
                    }
                  })
                })
              }
            })
            return acc
          }, 0)
          ?.toFixed(2)
      )
    },
    enableAutoPaymentOnChangeInvoiceType() {
      if (
        this.invoiceType === PriceTypeEnum.ONETIME &&
        Object.keys(this.paymentDepositSchedule).length > 0
      ) {
        this.toggleEnableAutoPayment(true)
      } else if (this.invoiceType === PriceTypeEnum.RECURRING) {
        this.toggleEnableAutoPayment(true)
      }
    },
  },
})
