<script setup lang="ts">
import { ref, PropType, computed, Ref, watch } from 'vue'
import {
  ICustomFieldsLinkageForm,
  ModalEvents,
} from '../../../types/components'
import {
  DOC_VAR_PREFIX,
  ObjectForLinkedField,
  SPECIAL_CHAR_REGEX_DOC_VAR,
} from '../../../const'
import { CustomModal } from '../CustomModal'
import {
  UIRadio,
  UIRadioGroup,
  UIForm,
  UIFormItem,
  UIInput,
  UIInputGroup,
  UIButton,
  DropdownTreeOption,
} from '@gohighlevel/ghl-ui'
import { TextInputIcon } from '@gohighlevel/ghl-icons/24/outline'
import { useI18n } from 'vue-i18n'
import { UIDropdownTree } from '../../TreeDropdown'
import { IElement } from '../../../types'

const { t } = useI18n()
const emits = defineEmits([ModalEvents.ON_SUCCESS, ModalEvents.ON_CANCEL])

const props = defineProps({
  element: Object as PropType<IElement>,
  show: {
    type: Boolean,
    default: false,
  },
  availableDocumentVariables: {
    type: Array,
    default: () => [],
  },
  menuOptions: {
    type: Array as PropType<DropdownTreeOption[]>,
    default: () => [],
  },
  contactCustomValueMapping: {
    type: Object,
    default: () => {
      return {}
    },
  },
  operationType: {
    type: String, // add / edit
    default: 'add',
  },
  operationValue: {
    type: Object,
    default: () => {
      return {}
    },
  },
})

const formRef = ref()

const recipientId = computed(
  () => props.element?.component?.options?.recipient || ''
)

const options = computed(() => {
  return JSON.parse(JSON.stringify(props.menuOptions))
})

const handleChange = (event: any) => {
  const value = event.target.value
  formValue.value.objectName = value
  if (value === ObjectForLinkedField.DOCUMENT_VARIABLE) {
    formValue.value.customFieldKey = ''
    formValue.value.customFieldLabel = ''
    formValue.value.customFieldId = ''
  } else if (value === ObjectForLinkedField.CONTACT_CUSTOM_VALUES) {
    formValue.value.documentFieldKey = ''
  }
}

const onSelect = (value: string, option: any) => {
  formValue.value.customFieldKey = value
  formValue.value.customFieldLabel = option.label
  formValue.value.customFieldId = option.id
  formValue.value.customFieldCategory = option.category
  formValue.value.customFieldType = option.type
  formRefValid()
}

const getDocVarSplit = (fieldKey: string) => {
  const docVar = fieldKey.split('.')
  let docPrefix = ''
  let docVariable = ''
  if (docVar.length === 2) {
    docPrefix = docVar[0]
    docVariable = docVar[1]
  }
  return { docPrefix, docVariable }
}

const setFormValue = (cvlData: { type: string; data: any; element: any }) => {
  formValue.value = {
    contactId: cvlData.element?.component?.options?.recipient || '',
    entityName: cvlData.element?.component?.options?.entityName || '',
    elementId: cvlData.element?.id,
    elementType: cvlData.element?.type,
    documentFieldKey:
      cvlData.data.objectType === ObjectForLinkedField.DOCUMENT_VARIABLE
        ? getDocVarSplit(cvlData?.data?.fieldKey)?.docVariable
        : '',
    objectName: cvlData?.data?.objectType,
    customFieldKey:
      cvlData.data.objectType === ObjectForLinkedField.CONTACT_CUSTOM_VALUES
        ? cvlData?.data?.fieldKey
        : '',
    customFieldLabel: cvlData.data.fieldLabel || '',
    customFieldId: cvlData.data.id || '',
    customFieldCategory: cvlData.data.category || '',
    customFieldType: cvlData.data.dataType || '',
  }
}

const setDefaultFormValue = () => {
  return {
    contactId: props.element?.component?.options?.recipient || '',
    entityName: props.element?.component?.options?.entityName || '',
    elementId: props.element?.id,
    elementType: props.element?.type,
    documentFieldKey: '',
    objectName: ObjectForLinkedField.CONTACT_CUSTOM_VALUES,
    customFieldKey: '',
    customFieldLabel: '',
    customFieldId: '',
    customFieldCategory: '',
    customFieldType: '',
  }
}

const formRefValid = async (): Promise<string[]> => {
  const allErrors: string[] = []
  await new Promise<void>(resolve => {
    formRef.value?.getForm()?.validate((err: undefined | string[]) => {
      if (err && err.length > 0) {
        err?.forEach(e => {
          if (e && e.length > 0) {
            e.forEach(m => {
              allErrors.push(m?.message)
            })
          }
        })
      }
      resolve()
    })
  })
  return allErrors
}

const objectForLinkedField = ref([
  {
    label: t('common.contactCustomValue'),
    value: ObjectForLinkedField.CONTACT_CUSTOM_VALUES,
  },
  {
    label: t('common.documentVariable'),
    value: ObjectForLinkedField.DOCUMENT_VARIABLE,
  },
])

const rules = {
  objectName: {
    required: true,
    message: t('validations.required.objectName'),
    trigger: 'blur',
  },
  documentFieldKey: {
    required: false,
    validator(rule: any, value: any) {
      if (SPECIAL_CHAR_REGEX_DOC_VAR.test(value)) {
        return new Error(t('validations.invalid.specialCharacter'))
      } else if (/\s/g.test(value)) {
        return new Error(t('validations.invalid.whiteSpace'))
      } else if (
        props.availableDocumentVariables.includes(`${DOC_VAR_PREFIX}.${value}`)
      ) {
        return new Error(t('validations.invalid.documentVariableExist'))
      }
      return true
    },
    trigger: ['input', 'blur'],
  },
  customFieldKey: {
    required: false,
    validator(rule: any, value: any) {
      if (contactFieldKeyMap.value.includes(value)) {
        return new Error(t('validations.invalid.customFieldExist'))
      }
      return true
    },
    trigger: ['input', 'blur', 'change'],
  },
}

const contactFieldKeyMap = computed(() => {
  if (props.contactCustomValueMapping[recipientId.value]) {
    return props.contactCustomValueMapping[recipientId.value]?.map(
      ({ fieldKey }) => fieldKey
    )
  }
  return []
})

const formValue: Ref<ICustomFieldsLinkageForm> = ref(setDefaultFormValue())

const isFormValid = computed(() => {
  const allErrors: string[] = []

  if (
    (formValue.value.objectName === ObjectForLinkedField.DOCUMENT_VARIABLE &&
      !formValue.value.documentFieldKey) ||
    (formValue.value.objectName ===
      ObjectForLinkedField.CONTACT_CUSTOM_VALUES &&
      !formValue.value.customFieldKey)
  ) {
    allErrors.push(t('validations.required.variable'))
  }

  // no need to emit the event if its document variable and if its already there in availableDocumentVariables
  if (formValue.value.objectName === ObjectForLinkedField.DOCUMENT_VARIABLE) {
    if (SPECIAL_CHAR_REGEX_DOC_VAR.test(formValue.value.documentFieldKey)) {
      allErrors.push(t('validations.invalid.specialCharacter'))
    } else if (/\s/g.test(formValue.value.documentFieldKey)) {
      allErrors.push(t('validations.invalid.whiteSpace'))
    }
    if (
      props.availableDocumentVariables.includes(
        `${DOC_VAR_PREFIX}.${formValue.value.documentFieldKey}`
      )
    ) {
      allErrors.push(t('validations.invalid.documentVariableExist'))
    }
  }

  // no need to emit the event if its contact custom value and if its already there in contactCustomValueMapping
  if (
    formValue.value.objectName === ObjectForLinkedField.CONTACT_CUSTOM_VALUES &&
    recipientId.value &&
    contactFieldKeyMap.value.includes(formValue.value.customFieldKey)
  ) {
    allErrors.push(t('validations.invalid.customFieldExist'))
  }

  return allErrors
})

const onSuccessForms = async () => {
  if (contactFieldKeyMap.value.includes(formValue.value.customFieldKey)) {
    return
  }

  if (isFormValid.value && isFormValid.value.length > 0) {
    return
  }

  const form = {
    ...formValue.value,
    ...(formValue.value.objectName ===
      ObjectForLinkedField.DOCUMENT_VARIABLE && {
      documentFieldKey: `${DOC_VAR_PREFIX}.${formValue.value.documentFieldKey}`,
    }),
    operationType: props.operationType,
    operationValue: props.operationValue,
  }
  emits(ModalEvents.ON_SUCCESS, form)
}

const onCancelForms = () => {
  emits(ModalEvents.ON_CANCEL)
}

watch(
  () => [props.show, props.operationType],
  ([_newShowValue, newOperationTypeValue]) => {
    if (newOperationTypeValue === 'edit') {
      setFormValue(props.operationValue)
    } else {
      formValue.value = setDefaultFormValue()
    }
  },
  { immediate: true }
)
</script>

<template>
  <CustomModal
    :width="550"
    :show="props.show"
    :modalType="'primary'"
    :title="$t('common.updateCustomFieldsWithResponse')"
    :description="$t('common.updateCustomFieldsWithResponseDescription')"
    @on-cancel="onCancelForms"
    @on-success="onSuccessForms"
    id="add-link-fields-modal"
    :cancelActionText="$t('common.cancel')"
    :successActionText="$t('common.linkAndSave')"
    :headerIcon="false"
    :disabled="isFormValid && isFormValid?.length === 0 ? false : true"
  >
    <div id="custom-field-linkage-modal"></div>
    <UIForm id="modal-form" ref="formRef" :model="formValue" :rules="rules">
      <UIFormItem :label="$t('common.objectName')" path="objectName">
        <UIRadioGroup
          :value="formValue.objectName"
          :name="'radio-btn-link-fields'"
          :disabled="false"
          :id="'radio-btn_doc-' + element?.id"
          :size="'medium'"
        >
          <UIRadio
            v-for="linkField in objectForLinkedField"
            :id="'radio-btn-' + linkField.value"
            :key="linkField.value"
            :value="linkField.value"
            :disabled="false"
            :label="linkField.label"
            @change="handleChange"
          />
        </UIRadioGroup>
      </UIFormItem>

      <UIFormItem
        :label="$t('common.documentVariable')"
        path="documentFieldKey"
        v-if="formValue.objectName === ObjectForLinkedField.DOCUMENT_VARIABLE"
      >
        <UIInput
          id="modal-form-document-field-input"
          v-model="formValue.documentFieldKey"
          :placeholder="$t('common.variableName')"
        />
      </UIFormItem>

      <UIFormItem
        :label="$t('common.customValueVariable')"
        path="customFieldKey"
        v-if="
          formValue.objectName === ObjectForLinkedField.CONTACT_CUSTOM_VALUES
        "
      >
        <UIInputGroup>
          <UIInput
            :readonly="true"
            v-model="formValue.customFieldLabel"
            id="modal-form-custom-field-input"
            :placeholder="$t('common.customValue')"
            :autofocus="false"
            :disabled="true"
          />
          <UIButton
            id="button-custom-field"
            :ghost="true"
            class="custom-value-input-button"
          >
            <UIDropdownTree
              :key="element?.id"
              :options="options"
              :to="'#custom-field-linkage-modal'"
              size="small"
              :popoverId="'popover-filed-link-' + element?.id"
              @onSelect="onSelect"
              ><template #trigger>
                <div class="w-full h-full custom-value-dropdown-icon">
                  <TextInputIcon class="w-6" />
                </div>
              </template>
            </UIDropdownTree>
          </UIButton>
        </UIInputGroup>
      </UIFormItem>
    </UIForm>
  </CustomModal>
</template>

<style lang="scss" scoped>
#button-custom-field {
  --n-padding: 0px 6px !important;
}

.custom-value-input-button {
  --n-padding: 0px 6px !important;

  .n-button.n-button--default-type:not(.icon-only) {
    --n-padding: 0px 6px !important;
  }

  .custom-value-dropdown-icon {
    padding: 0px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
