<script lang="ts" setup>
import { useActionBarStore } from '@/store/actionBar'
import { useBuilderStore } from '@/store/builder'
import {
  CurrencyDollarCircleIcon,
  File04Icon,
  FlipBackwardIcon,
  FlipForwardIcon,
  PlusIcon,
  Settings04Icon,
  Settings01Icon,
  User03Icon,
  VariableIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import { UIButton, UITooltip } from '@gohighlevel/ghl-ui'
import TextEditorToolbar from '../editor/TextEditorToolbar.vue'
import { usePropertiesDrawer } from '@/composable/usePropertiesDrawer'
import { computed } from 'vue'

const DefaultStackSizeWithoutChanges = 2

const store = useActionBarStore()
const storeBuilder = useBuilderStore()
const { toggleDrawer, isOpen } = usePropertiesDrawer()
const open = computed(() => isOpen.value)
const canUndo = computed(
  () => storeBuilder.undoRedoStackPointer > DefaultStackSizeWithoutChanges
)
const canRedo = computed(() => {
  return (
    storeBuilder.undoSelected &&
    storeBuilder.undoRedoStackPointer < storeBuilder.undoRedoStack.length - 1
  )
})
</script>

<template>
  <div class="flex items-center justify-between h-12 border-b-2 px-2">
    <div class="flex-grow-0 border-r px-4 h-full flex items-center">
      <UITooltip>
        <template #trigger>
          <UIButton
            id="btn_elements"
            :active="store.elementsActive"
            quaternary
            size="small"
            @click="store.toggleElements()"
            :class="`${
              !storeBuilder.isDocumentEditable
                ? 'pointer-events-none opacity-50'
                : ''
            }`"
          >
            <PlusIcon class="w-5 h-5 text-black" />
          </UIButton>
        </template>
        {{ $t('common.addAnElement') }}
      </UITooltip>

      <UITooltip>
        <template #trigger>
          <UIButton
            id="btn_pages"
            :active="store.pagesActive"
            quaternary
            size="small"
            @click="store.togglePages()"
            :class="`${
              !storeBuilder.isDocumentEditable
                ? 'pointer-events-none opacity-50'
                : ''
            }`"
          >
            <File04Icon class="w-5 h-5 text-black" />
          </UIButton>
        </template>
        {{ $t('common.pages') }}
      </UITooltip>
      <!-- <UIButton
        id="btn_undo"
        size="small"
        quaternary
        @click="store.toggleSettings()"
        :active="store.settingsActive"
      >
        <Settings01Icon class="w-5 h-5 text-black" />
      </UIButton> -->
      <UITooltip>
        <template #trigger>
          <UIButton
            id="btn_variables"
            :active="store.documentVariableActive"
            quaternary
            size="small"
            @click="store.toggleDocumentVariable()"
            :class="`${
              !storeBuilder.isDocumentEditable
                ? 'pointer-events-none opacity-50'
                : ''
            }`"
          >
            <VariableIcon class="w-5 h-5 text-black" />
          </UIButton>
        </template>
        {{ $t('common.documentVariables') }}
      </UITooltip>

      <UITooltip>
        <template #trigger>
          <UIButton
            id="btn_recipients"
            :active="store.recipientsActive"
            quaternary
            size="small"
            @click="store.toggleRecipients()"
            :class="`${
              !storeBuilder.isDocumentEditable
                ? 'pointer-events-none opacity-50'
                : ''
            }`"
          >
            <User03Icon class="w-5 h-5 text-black" />
          </UIButton>
        </template>
        {{ $t('common.recipients') }}
      </UITooltip>
      <UITooltip>
        <template #trigger>
          <UIButton
            class="relative"
            id="btn_payment_settings"
            :active="store.paymentSettings"
            quaternary
            size="small"
            @click="store.togglePaymentSettings()"
            :class="`${
              !storeBuilder.isDocumentEditable
                ? 'pointer-events-none opacity-50'
                : ''
            }`"
          >
            <!-- <span
              class="absolute"
              style="
                padding: 0 4px;
                line-height: 15px;
                font-size: 0.8rem;
                border: 1px solid #af8205;
                border-radius: 3px;
                background: #ffbc00;
                color: #0c2d3f;
                right: -20px;
                top: -6px;
              "
            >
              {{ $t('common.new') }}
            </span> -->
            <CurrencyDollarCircleIcon class="w-5 h-5 text-black" />
          </UIButton>
        </template>
        {{ $t('common.paymentSettings') }}
      </UITooltip>
      <UITooltip>
        <template #trigger>
          <UIButton
            class="relative"
            id="btn_document_settings"
            :active="store.documentSettings"
            quaternary
            size="small"
            @click="store.toggleDocumentSettings()"
            :class="`${
              !storeBuilder.isDocumentEditable
                ? 'pointer-events-none opacity-50'
                : ''
            }`"
          >
            <span
              class="absolute"
              style="
                padding: 0 4px;
                line-height: 15px;
                font-size: 0.8rem;
                border: 1px solid #af8205;
                border-radius: 3px;
                background: #ffbc00;
                color: #0c2d3f;
                right: -20px;
                top: -6px;
              "
            >
              {{ $t('common.new') }}
            </span>
            <Settings01Icon class="w-5 h-5 text-black" />
          </UIButton>
        </template>
        {{ $t('common.settings') }}
      </UITooltip>
    </div>
    <div class="flex-1 px-4 h-full flex items-center justify-center">
      <TextEditorToolbar />
    </div>
    <div class="flex-grow-0 justify-end px-2 border-l h-full flex items-center">
      <div class="flex">
        <UIButton
          id="btn_undo"
          quaternary
          size="small"
          @click="storeBuilder.undo()"
          :class="`${
            !storeBuilder.isDocumentEditable || !canUndo
              ? 'pointer-events-none opacity-50'
              : ''
          }`"
        >
          <FlipBackwardIcon class="w-5 h-5 text-black" />
        </UIButton>
        <UIButton
          id="btn_redo"
          quaternary
          size="small"
          @click="storeBuilder.redo()"
          :class="`${
            !storeBuilder.isDocumentEditable || !canRedo
              ? 'pointer-events-none opacity-50'
              : ''
          }`"
        >
          <FlipForwardIcon class="w-5 h-5 text-black" />
        </UIButton>
      </div>
    </div>
    <div class="border-l w-16 flex justify-center h-full items-center">
      <UIButton
        id="btn_undo"
        :active="open"
        quaternary
        size="small"
        @click="toggleDrawer"
        :class="`${
          !storeBuilder.isDocumentEditable
            ? 'pointer-events-none opacity-50'
            : ''
        }`"
      >
        <Settings04Icon class="w-5 h-5 text-black" />
      </UIButton>
    </div>
  </div>
</template>
