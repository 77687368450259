<script lang="ts" setup>
import { IPage, DraggablePageEvents } from '../../types'
import { PropType, computed } from 'vue'
import { DraggableList } from '../DraggableList'
import { DragHandleSVG, NoPreviewAvailable } from '../../assets'
import { PagePreviewThumbnail } from '../PagePreviewThumbnail'

const emits = defineEmits([
  DraggablePageEvents.ON_PAGE_CLICKED,
  DraggablePageEvents.ON_PAGE_REORDERED,
])

const props = defineProps({
  pages: {
    type: Array as PropType<IPage[]>,
    default: () => [],
  },
  draggable: {
    type: Boolean,
    default: false,
  },
})

const pageLength = computed(() => props.pages?.length)
const pageClicked = (page: IPage) => {
  emits(DraggablePageEvents.ON_PAGE_CLICKED, page)
}

const reorderArray = ({
  oldIndex,
  newIndex,
}: {
  oldIndex: number
  newIndex: number
}) => {
  const newArray = [...(props.pages || [])]
  const movedItem = newArray.splice(oldIndex, 1)[0]
  newArray.splice(newIndex, 0, movedItem)
  emits(DraggablePageEvents.ON_PAGE_REORDERED, newArray)
}
</script>

<template>
  <div class="flex flex-col justify-items-end proposal-pages-scroll">
    <DraggableList
      :list="pages"
      @on-update="reorderArray"
      :draggable="draggable"
      class="file-drag--list"
    >
      <template #listItem="slotProps">
        <div class="w-full flex justify-center items-center mb-5">
          <div class="flex justify-start mb-5 gap-2">
            <div class="flex flex-col justify-start items-center rounded-sm">
              <div
                class="flex flex-col justify-start items-center bg-gray-500 p-1 handle-bg"
              >
                <span
                  class="h-[22px] w-[18px] min-w-[18px] text-center rounded-sm mt-1 font-weight-bold text-white"
                >
                  {{ slotProps.index + 1 }}</span
                >
                <span class="handle" v-if="draggable">
                  <DragHandleSVG class="h-[22px] w-[18px]" />
                </span>
              </div>
            </div>
            <div
              @click="pageClicked(slotProps.data)"
              class="flex justify-center border w-40 h-52 shadow hover:shadow-lg cursor-pointer"
            >
              <PagePreviewThumbnail
                v-if="pageLength < 100"
                :page="slotProps.data"
                :pageLength="pageLength"
              />
              <NoPreviewAvailable v-else class="w-40 h-full" />
            </div>
          </div>
        </div>
      </template>
    </DraggableList>
  </div>
</template>

<style lang="scss" scoped>
.proposal-pages-scroll {
  height: calc(100vh - 200px);
  overflow-y: auto;
}
.handle ::v-deep(svg) {
  path {
    stroke: white;
  }
}
.handle-bg {
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}
</style>
