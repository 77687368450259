import { config } from '../config'
import { requests } from '../utils'

interface SearchContactParams {
  locationId: string
  page: number
  filters: Array<any>
  sort: Array<any>
  pageLimit: number
  justIds: boolean
}

export interface ContactSearchResponse {
  contacts: {
    id: string
    contactName: string
    email: string
    additionalEmails: {
      email: string
    }[]
    profilePhoto: string
    firstName: string
    lastName: string
  }[]
  total: number
}

const searchContacts = (params: SearchContactParams, signal?: AbortSignal) => {
  return requests(config?.CONTACT_SERVICE_URL).post<ContactSearchResponse>(
    'contacts/search',
    params,
    { signal }
  )
}

const ContactService = {
  searchContacts,
}

export default ContactService
