import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { EmailTemplateInfo, EmailTemplateService } from '../services'
import { useNotify } from './useNotify'

export const useEmailTemplate = () => {
  const { t } = useI18n()
  const { handleError } = useNotify()
  const isTemplateLoading = ref(false)
  const error = ref(null)
  const emailTemplateList = ref<EmailTemplateInfo[]>([])

  const cloneDefaultEmailTemplate = async ({
    locationId,
    altType,
    notificationType,
    templateName,
  }: {
    locationId: string
    altType: string
    notificationType: string
    templateName: string
  }) => {
    try {
      isTemplateLoading.value = true
      const { data } = await EmailTemplateService.cloneDefaultEmailTemplate({
        altId: locationId,
        altType,
        notificationType,
        templateName,
      })
      isTemplateLoading.value = false
      return data
    } catch (error) {
      console.error('Error cloning template', error)
      handleError(error)
      isTemplateLoading.value = false
      throw error
    }
  }

  const getEmailTemplates = async (locationId: string, templateId: string) => {
    try {
      isTemplateLoading.value = true
      const { data } = await EmailTemplateService.getEmailTemplates(
        locationId,
        templateId
      )
      if (data && data.length > 0) {
        return data[0]
      }
      return null
    } catch (error) {
      console.error('Error cloning template', error)
      handleError(error)
      isTemplateLoading.value = false
      throw error
    }
  }

  const fetchEmailTemplates = async (locationId: string) => {
    try {
      isTemplateLoading.value = true
      const response = await EmailTemplateService.getAllEmailTemplates({
        locationId: locationId as string,
      })
      emailTemplateList.value = [
        {
          value: 'default',
          label: t('common.default'),
          id: 'default',
        } as unknown as EmailTemplateInfo,
        ...response.data.map(emailInfo => {
          return {
            ...emailInfo,
            value: emailInfo.id,
            label: emailInfo.name,
          }
        }),
      ]
    } catch (err) {
      console.error(err)
      error.value = err
    } finally {
      isTemplateLoading.value = false
    }
  }

  return {
    isTemplateLoading,
    cloneDefaultEmailTemplate,
    getEmailTemplates,
    fetchEmailTemplates,
    emailTemplateList,
  }
}
