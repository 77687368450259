const override = false
interface Config {
  LOCATION_SERVICE_URL: string
  PROPOSAL_SERVICE_URL: string
  EMAIL_BUILDER_SERVICE_URL: string
  DOCUMENT_VIEWER_URL: string
  PAYMENTS_SERVICE_URL: string
  CUSTOM_DATA_SERVICE_URL: string
  EMAIL_SERVICE_URL: string
  CONTACT_SERVICE_URL: string
  INVOICE_SERVICE_URL: string
}
const configuration: Record<string, Config> = {
  development: {
    PROPOSAL_SERVICE_URL: 'http://localhost:5092/proposals',
    LOCATION_SERVICE_URL: 'https://staging.services.leadconnectorhq.com',
    EMAIL_BUILDER_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/emails',
    DOCUMENT_VIEWER_URL: 'http://localhost:3041',
    PAYMENTS_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/payments',
    CUSTOM_DATA_SERVICE_URL: 'https://staging.services.leadconnectorhq.com',
    EMAIL_SERVICE_URL: 'https://staging.backend.leadconnectorhq.com/emails',
    CONTACT_SERVICE_URL: 'https://staging.backend.leadconnectorhq.com',
    INVOICE_SERVICE_URL: 'https://staging.backend.leadconnectorhq.com',
  },
  staging: {
    PROPOSAL_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/proposals',
    LOCATION_SERVICE_URL: 'https://staging.services.leadconnectorhq.com',
    EMAIL_BUILDER_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/emails',
    DOCUMENT_VIEWER_URL: 'https://staging.sendlink.co',
    PAYMENTS_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/payments',
    CUSTOM_DATA_SERVICE_URL: 'https://staging.services.leadconnectorhq.com',
    EMAIL_SERVICE_URL: 'https://staging.backend.leadconnectorhq.com/emails',
    CONTACT_SERVICE_URL: 'https://staging.backend.leadconnectorhq.com',
    INVOICE_SERVICE_URL: 'https://staging.backend.leadconnectorhq.com',
  },
  production: {
    PROPOSAL_SERVICE_URL: 'https://services.leadconnectorhq.com/proposals',
    LOCATION_SERVICE_URL: 'https://services.leadconnectorhq.com',
    EMAIL_BUILDER_SERVICE_URL: 'https://services.leadconnectorhq.com/emails',
    DOCUMENT_VIEWER_URL: 'https://sendlink.co',
    PAYMENTS_SERVICE_URL: 'https://services.leadconnectorhq.com/payments',
    CUSTOM_DATA_SERVICE_URL: 'https://services.leadconnectorhq.com',
    EMAIL_SERVICE_URL: 'https://backend.leadconnectorhq.com/emails',
    CONTACT_SERVICE_URL: 'https://backend.leadconnectorhq.com',
    INVOICE_SERVICE_URL: 'https://backend.leadconnectorhq.com',
  },
}

if (override) {
  // do overrides here
}
export const config =
  configuration[(process.env.mode || process.env.NODE_ENV) as string]
