import { AxiosError, isAxiosError } from 'axios'

import { useNotification } from '@gohighlevel/ghl-ui'

export const useNotify = () => {
  const notification = useNotification()

  const enableNotification = (
    type: 'success' | 'info' | 'warning' | 'error',
    title: string,
    message: string
  ) => {
    notification[type]({
      title,
      description: message,
      duration: 10000,
    })
  }

  const handleError = (err: Error | AxiosError) => {
    if (isAxiosError(err)) {
      const axiosError = err as AxiosError
      if (axiosError.response?.data) {
        enableNotification(
          'error',
          'Error',
          axiosError.response.data?.message as string
        )
      }
    }
  }
  const handleErrorMessage = (err: string) => {
    enableNotification('error', 'Error', err)
  }
  const handleInfo = (title: string, message?: '') => {
    enableNotification('info', title, message)
  }

  const handleSuccess = (message: string) => {
    enableNotification('success', 'Success', message)
  }
  return {
    handleError,
    handleSuccess,
    handleInfo,
    handleErrorMessage,
  }
}
