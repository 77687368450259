import { config } from '../config'
import { requests } from '../utils'

export const InvoiceService = {
  getInvoiceSettings: (params: { altId: string; altType: string }) => {
    return requests(config.INVOICE_SERVICE_URL).get(
      `/invoices/settings?altId=${params.altId}&altType=${params.altType}`,
      {
        headers: {
          'location-id': params.altId,
          'internal-channel': `proposals`,
        },
      }
    )
  },
}
