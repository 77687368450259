<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { UIButton, UIDropdown, renderIcon } from '@gohighlevel/ghl-ui'
import { Link02Icon, Mail01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { useDocument } from '@/composition'
import { ref, computed, onMounted } from 'vue'
import {
  DefaultValidationObjectForDocument,
  DocumentStatus,
  SentMedium,
  SendEmailModal,
  useCustomNotificationSettings,
  ccRecipient,
  ProposalsMailTemplates,
  EmailTemplatePreviewEvents,
} from '@gohighlevel/ghl-proposals-common'
import { useAppStore } from '@/store/app'
import { useBuilderStore } from '@/store/builder'
import LinkGenerationModal from './LinkGenerationModal.vue'
import { useEmitter } from '@/composable/useEmitter'
import DocumentValidationModal from '@/components/modals/DocumentValidationModal.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const { t } = useI18n()
const sendingRef = ref(false)
const appStore = useAppStore()
const builderStore = useBuilderStore()
const emitter = useEmitter()
const showErrorModal = ref(false)
const showSendModal = ref(false)
const docName = ref(appStore.document?.name || '')
const docCCRecipients = ref<ccRecipient[]>([])
const validations = ref(DefaultValidationObjectForDocument)
const ccRecipients = computed(() => appStore.document?.ccRecipients || [])
const documentRevision = computed(
  () => appStore.document?.documentRevision || 1
)
const bccRecipients = computed(() => [])
const isAllValid = ref(false)
const sendMediumDropDown = ref(false)
const documentTitle = computed(() => appStore.document?.name)
const {
  loading,
  saveDocument,
  validateDocument,
  sendDocument,
  getDocumentMeta,
} = useDocument()
const { fetchCustomNotificationSetting, notificationSetting } =
  useCustomNotificationSettings(route.params?.locationId as string)

const toggleModal = () => {
  showErrorModal.value = !showErrorModal.value
}

const toggleSendModal = (value: { documentTitle: string }) => {
  showSendModal.value = !showSendModal.value
  if (value) {
    docName.value = value.documentTitle
  }
}

const onClickSendButton = () => {
  appStore.sendAttempted = true
  validations.value = validateDocument()
  isAllValid.value = Object.values(validations.value).every(val => val === true)
  if (!isAllValid.value) {
    toggleModal()
  }

  onClickOutsideSendDropDown()
}

const onClickOutsideSendDropDown = () => {
  if (isAllValid.value) {
    sendMediumDropDown.value = !sendMediumDropDown.value
  }
}

const sendDocumentViaEmail = async () => {
  try {
    sendingRef.value = true
    // check the document state if the document state is on draft, then only save the document.
    // why we are loading the document is to avoid the duplicate tab issue where the document is not loaded and the user tries to send the document.
    const metaInfo = await getDocumentMeta()
    if (metaInfo.status === DocumentStatus.DRAFT) {
      await saveDocument()
    }
    appStore.document.name = docName.value
    appStore.document.ccRecipients = docCCRecipients.value
    const cc = docCCRecipients.value?.map(({ _id, ...rest }) => rest) || []
    await sendDocument(SentMedium.EMAIL, {
      name: docName.value,
      ccRecipients: cc,
    })
    showErrorModal.value = false
  } catch (error) {
    console.error(error)
  } finally {
    sendingRef.value = false
  }
}

const options = [
  {
    label: t('common.sendViaEmail'),
    key: 'send_via_email',
    icon: renderIcon(Mail01Icon),
    disabled: sendingRef.value,
  },
  {
    label: t('common.shareViaLink'),
    key: 'share_via_link',
    icon: renderIcon(Link02Icon),
    disabled: sendingRef.value,
  },
]

const onSelect = (value: string) => {
  if (value === 'send_via_email') {
    showSendModal.value = true
  }

  if (value === 'share_via_link') {
    emitter.emit('open-link-generation-model')
  }

  onClickOutsideSendDropDown()
}

const recipients = computed(() => {
  return appStore.document?.recipients
})

const onUpdateDocumentTitle = (documentName: string) => {
  appStore.document.name = documentName
  docName.value = documentName
}

const onSendEmailModal = (value: {
  documentTitle: string
  emailTo: string[]
  emailCc: string[]
  emailBcc: string[]
  medium: SentMedium
  ccRecipients: ccRecipient[]
  bccRecipients: ccRecipient[]
}) => {
  appStore.setCCAndBccRecipients(value.ccRecipients)
  docName.value = value.documentTitle
  docCCRecipients.value = value.ccRecipients
  sendDocumentViaEmail()
}

const ccRecipientsFromSettings = computed(() => {
  return notificationSetting.value?.ccRecipients
})

onMounted(() => {
  fetchCustomNotificationSetting()
  docName.value = appStore.document?.name || ''
})

const editEmailTemplate = ({
  templateId,
  allowSavingNewTemplate,
}: {
  templateId: string
  allowSavingNewTemplate: boolean
}) => {
  appStore.handshake.then((parent: any) => {
    parent.emit(
      EmailTemplatePreviewEvents.EDIT_EMAIL_TEMPLATE_DOCUMENT_CONTRACT,
      {
        locationId: appStore.document.locationId,
        templateId,
        allowSavingNewTemplate,
      }
    )
  })
}
</script>

<template>
  <DocumentValidationModal :show="showErrorModal" @on-cancel="toggleModal" />
  <SendEmailModal
    :show="showSendModal"
    @on-cancel="toggleSendModal"
    :recipients="recipients"
    :locationId="appStore.document.locationId"
    :isDocumentSending="sendingRef"
    :documentName="documentTitle"
    @on-update-document-title="onUpdateDocumentTitle"
    @on-success="onSendEmailModal"
    :ccRecipientsFromSettings="ccRecipientsFromSettings"
    :ccRecipientsFromDocument="ccRecipients"
    :bccRecipientsFromDocument="bccRecipients"
    :notificationType="ProposalsMailTemplates.SEND_PROPOSAL"
    :documentNotificationSettings="appStore.document.notificationSettings"
    @edit-email-template-document-contract-from-preview="editEmailTemplate"
    :documentRevision="documentRevision"
    :documentStatus="appStore.document.status"
  />
  <LinkGenerationModal />
  <div class="flex gap-4">
    <div class="relative" v-if="builderStore.isDocumentEditable">
      <UIButton
        :loading="loading"
        :disabled="loading"
        ghost
        type="default"
        @click="saveDocument"
        id="save-document-btn"
      >
        {{ $t('common.save') }}</UIButton
      >
      <span
        class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1"
        v-if="appStore.unsavedChanges"
      >
        <span
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-warning-400 opacity-75"
        ></span>
        <span
          class="relative inline-flex rounded-full h-3 w-3 bg-warning-500"
        ></span>
      </span>
    </div>

    <UIDropdown
      :show="sendMediumDropDown"
      :options="options"
      @select="onSelect"
      id="send-document-dropdown"
      :size="'medium'"
      @clickoutside="onClickOutsideSendDropDown"
    >
      <UIButton
        :loading="sendingRef"
        :disabled="sendingRef"
        ghost
        id="send-document-btn"
        class="px-5"
        type="primary"
        :onClick="onClickSendButton"
        >{{
          appStore.document.status !== DocumentStatus.DRAFT
            ? $t('common.resend')
            : $t('common.send')
        }}</UIButton
      >
    </UIDropdown>
  </div>
</template>

<style lang="scss">
.is-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.recipient-link {
  .n-divider.n-divider--no-title {
    margin-bottom: 0;
  }
}
</style>
