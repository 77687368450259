import { ref } from 'vue'
import { InvoiceService } from '../services'

const loading = ref(false)
const invoiceSettings = ref({})
export const useInvoice = () => {
  const getInvoiceSettings = async (locationId: string) => {
    try {
      loading.value = true
      const { data } = await InvoiceService.getInvoiceSettings({
        altId: locationId,
        altType: 'location',
      })
      invoiceSettings.value = data
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  return {
    getInvoiceSettings,
    invoiceSettings,
    loading,
  }
}
