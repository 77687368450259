<script lang="ts" setup>
import {
  PlusIcon,
  Ticket01Icon,
  InfoCircleIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UITextMdMedium,
  UITextSmMedium,
  UITextSmRegular,
  UITooltip,
  UIDivider,
} from '@gohighlevel/ghl-ui'
import { computed, onMounted, PropType, reactive, ref } from 'vue'
import { getProductListColumns } from './product-list.props'
import { useCurrency, useLineItems, useProducts } from '@/composition'
import AddNewProductItemModal from './AddNewProductItemModal.vue'
import {
  ProductLineEvents,
  BusinessProduct,
  BusinessProductOption,
  BusinessProductPrice,
  BusinessProductPriceOption,
  IElement,
  LineItem,
  ProductListElementOptions,
  ELEMENTS_META,
  DiscountType,
  IRRule,
  PriceTypeEnum,
  PriceItemIntervalType,
  PaymentScheduleSummary,
  PaymentDepositSchedule,
  PaymentSchedule,
} from '@gohighlevel/ghl-proposals-common'
import DiscountModal from '@/components/editor/elements/CProductList/DiscountModal.vue'
import { useElementOption } from '@/composable/elementOptions'
import AddNewProductModal from '@/components/editor/elements/CProductList/AddNewProductModal.vue'
import { generateId } from '@/util'
import { DynamicTable } from '@gohighlevel/ghl-proposals-common'
import { useProductListStore } from '@/store/productListStore'
import { useBuilderStore } from '@/store/builder'
import { cloneDeep } from 'lodash'
import { useEmitter } from '@/composable/useEmitter'
import { useAppStore } from '@/store/app'
import { useProductListActions } from '@/composable/useProductListActions'
import { useActionBarStore } from '@/store/actionBar'

const eventBus = useEmitter()
const productListStore = useProductListStore()
const { updateSelectedLineItem } = useProductListActions()
const builderStore = useBuilderStore()
const appStore = useAppStore()
const actionBarStore = useActionBarStore()
const { loading, fetchBusinessProducts } = useProducts()
const { getCurrency, formatCurrency, getCurrencySymbol } = useCurrency()
const showModal = ref(false)
const showDiscountModal = ref(false)
const showAddNewProductModal = ref(false)
const productCurrencySymbol = computed(() =>
  getCurrencySymbol(productListStore.currency || getCurrency())
)
const currencyCode = computed(() => productListStore.currency || getCurrency())
const grandTotal = computed(
  () => productListStore.getGrandTotalForProductList() || 0
)

const props = defineProps({
  element: {
    type: Object as PropType<IElement<ProductListElementOptions>>,
    required: true,
  },
})
const productBuilderOptions = computed(
  (): ProductListElementOptions => props.element.component.options
)
const discountType = computed(
  () => props.element.component.options.discountType
)
const discountValue = computed(() => productBuilderOptions.value.discountValue)
const elementId = computed(() => props.element?.id)
const hasDiscount = computed((): boolean => discountValue.value > 0)
const data = computed(() => productBuilderOptions.value.lineItems)
const { subTotal, amountDue, totalDiscount, appliedTax } = useLineItems(
  elementId.value
)
const productList = reactive<BusinessProductOption[]>([])
const columns = ref(
  getProductListColumns(elementId.value, props?.element?.version)
)
const activeRow = computed(() => productListStore.activeRow!)
const { updateOption } = useElementOption()

const mutateProductListToOptions = (list: BusinessProduct[]) => {
  return list.map(({ prices, ...rest }) => ({
    ...rest,
    label: rest.name,
    value: rest._id,
    prices: (prices as BusinessProductPrice[]).map(priceListItem => ({
      ...priceListItem,
      label: priceListItem.name,
      value: priceListItem._id,
    })),
  }))
}

const setCurrencyForProductList = () => {
  const allLineItems = builderStore.getAllLineItems
  if (allLineItems.length > 0) {
    const currency =
      allLineItems[0]?.currency || appStore.document?.grandTotal?.currency
    productListStore.setProductListCurrency(currency as string)
    builderStore.syncCurrency(currency as string)
  }
}

onMounted(async () => {
  setCurrencyForProductList()
  const response = await fetchBusinessProducts()
  Object.assign(productList, mutateProductListToOptions(response?.products))
})

const createData = ({
  productId,
  priceId,
  isSetupFeeItem,
  setupFee,
}: {
  productId: string
  priceId: string
  isSetupFeeItem?: boolean
  setupFee?: number
}): LineItem => {
  const product = productList.find(
    p => p._id === productId
  ) as BusinessProductOption
  const priceItem = product?.prices?.find(
    p => p._id === priceId
  ) as BusinessProductPriceOption

  if (!productListStore.currency) {
    productListStore.setProductListCurrency(priceItem.currency)
    builderStore.syncCurrency(priceItem.currency)
  }

  const amount =
    isSetupFeeItem && setupFee && setupFee > 0 ? setupFee : priceItem.amount
  const productName = isSetupFeeItem
    ? product.name + ' - Setup fee'
    : product.name

  const productDescription = isSetupFeeItem ? '' : product.description || ''

  return {
    description: productDescription,
    name: productName,
    priceDescription: priceItem.description || '',
    currency: priceItem.currency,
    qty: 1,
    price: amount,
    priceType: priceItem.type,
    subtotal: amount,
    productId,
    priceId,
    ...(isSetupFeeItem && { isSetupFeeItem }),
    key: generateId(),
    priceText: formatCurrency(amount, getCurrencySymbol(priceItem.currency)),
    taxes: [],
    rrule: {} as IRRule,
    selected: true,
    minQty: 1,
    maxQty: 5,
    optional: false,
    qtyEditable: false,
    disabled: true,
    taxInclusive: !!product.taxInclusive,
    ...(priceItem?.recurring
      ? {
          recurring: {
            intervalCount: priceItem.recurring.intervalCount,
            interval:
              PriceItemIntervalType[
                priceItem.recurring
                  .interval as unknown as keyof typeof PriceItemIntervalType
              ],
          },
        }
      : null),
  }
}

const createTwoLineItemWithSetupFee = ({
  productId,
  priceId,
  setupFee,
}: {
  productId: string
  priceId: string
  setupFee: number
}) => {
  const newSetupFeeData = createData({
    productId,
    priceId,
    isSetupFeeItem: true,
    setupFee,
  })
  const newData = createData({ productId, priceId })
  updateOption(
    ELEMENTS_META.LINE_ITEMS,
    [...data.value, newSetupFeeData, newData] as any,
    props?.element?.id,
    props?.element?.type
  )
  setActiveRow(newData)
}

const createLineItem = ({
  productId,
  priceId,
}: {
  productId: string
  priceId: string
}) => {
  const newData = createData({ productId, priceId })
  updateOption(
    ELEMENTS_META.LINE_ITEMS,
    [...data.value, newData] as any,
    props?.element?.id,
    props?.element?.type
  )
  setActiveRow(newData)
}

const onSubmitAddItem = ({
  productId,
  priceId,
  setupFee,
  priceType,
}: {
  productId: string
  priceId: string
  setupFee?: number
  priceType?: string
}) => {
  if (priceType === PriceTypeEnum.RECURRING && setupFee && setupFee > 0) {
    createTwoLineItemWithSetupFee({ productId, priceId, setupFee })
  } else {
    createLineItem({ productId, priceId })
  }
  closeAddItemModal()
  productListStore.updateInvoiceTypeIfRecurringProductExist()
}

const toggleDiscountModal = () => {
  showDiscountModal.value = !showDiscountModal.value
}
const toggleAddItemModal = () => {
  showModal.value = !showModal.value
}
const closeAddItemModal = () => {
  showModal.value = false
}
const toggleAddNewProductModal = () => {
  showAddNewProductModal.value = !showAddNewProductModal.value
}
const openAddNewProductModal = () => {
  showModal.value = false
  showAddNewProductModal.value = true
  eventBus.emit(ProductLineEvents.OPEN_ADD_PRODUCT_MODAL)
}

const addNewProductToProductsList = (product: BusinessProduct) => {
  Object.assign(productList, [
    ...mutateProductListToOptions([product]),
    ...productList,
  ])
  onSubmitAddItem({ productId: product._id, priceId: product.prices[0]._id })
}

const setActiveRow = (item: LineItem) => {
  builderStore.updateActiveElement(props.element)
  productListStore.setActiveRow(item, props.element.id)
  actionBarStore.setPaymentSettings(true)
}

const onDeleteRow = (row: LineItem) => {
  const idx = data.value.findIndex(({ key }) => row.key === key)
  const items = cloneDeep(data.value)
  items.splice(idx, 1)
  updateOption(
    ELEMENTS_META.LINE_ITEMS,
    [...items],
    props?.element?.id,
    props?.element?.type
  )
  const allLineItemsLength = builderStore.totalLineItemsLength
  if (allLineItemsLength <= 0) {
    productListStore.setProductListCurrency('')
    builderStore.syncCurrency(getCurrency())
  }
  productListStore.updateInvoiceTypeIfRecurringProductExist()
}

const styles = computed(() => props.element?.responsiveStyles.large)
const customStyleForImageParent = computed(() => {
  return {
    backgroundColor: styles.value?.backgroundColor,
    marginTop: styles.value?.marginTop,
    marginBottom: styles.value?.marginBottom,
    marginLeft: styles.value?.marginLeft,
    marginRight: styles.value?.marginRight,
    paddingTop: styles.value?.paddingTop,
    paddingBottom: styles.value?.paddingBottom,
    paddingLeft: styles.value?.paddingLeft,
    paddingRight: styles.value?.paddingRight,
  }
})
const onSelectionChange = (row: LineItem, value: boolean) => {
  setActiveRow(row)
  updateSelectedLineItem('selected', value)
}

const showPaymentDepositSchedule = computed(() => {
  const allProductList = builderStore.getAllProductList()
  if (allProductList.length > 0) {
    const lastProductList = allProductList[allProductList.length - 1]
    return (
      lastProductList.id === props.element.id &&
      productListStore.paymentDepositSchedule &&
      Object.keys(productListStore.paymentDepositSchedule).length > 0
    )
  } else {
    return false
  }
})

const isLastProductList = computed(() => {
  const allProductList = builderStore.getAllProductList()
  if (allProductList.length > 0) {
    const lastProductList = allProductList[allProductList.length - 1]
    return lastProductList.id === props.element.id
  } else {
    return false
  }
})

const paymentDepositScheduleInfoCount = computed(() => {
  const allProductList = builderStore.getAllProductList()
  const length = allProductList.length
  return Array.from({ length }, (_, i) => i + 1).join(',')
})

const updatePaymentSchedule = (paymentScheduleInfo: PaymentSchedule) => {
  if (builderStore.isDocumentEditable) {
    productListStore.setPaymentDepositSchedule(paymentScheduleInfo)
  }
}
const removePaymentSchedule = () => {
  if (builderStore.isDocumentEditable) {
    productListStore.removePaymentDepositSchedule()
  }
}

const enablePaymentDeposit = computed(() => {
  return (
    productListStore.invoiceType === PriceTypeEnum.ONETIME &&
    grandTotal.value > 0
  )
})
</script>

<template>
  <div class="w-full flex flex-col overflow-auto">
    <div
      class="products-pricing-table p-4 border rounded-sm min-w-[400px]"
      :style="customStyleForImageParent"
    >
      <DynamicTable
        :key="elementId"
        :id="`dynamic-table-${props.element.id}`"
        :config="columns"
        :data="data"
        @on-row-delete="onDeleteRow"
        @on-row-select="setActiveRow"
        @on-selection-change="onSelectionChange"
        :allowRowDelete="builderStore.isDocumentEditable"
        :selectable="builderStore.isDocumentEditable"
        :uniqueKey="'key'"
        :activeRow="
          builderStore.activeElementId === props.element.id ? activeRow : {}
        "
        :with-check-box="props.element.version > 1"
      />
      <div class="mx-4 mt-4" v-if="builderStore.isDocumentEditable">
        <div
          class="border-2 border-dashed flex justify-center px-5 py-3 cursor-pointer hover:bg-gray-100 rounded-md"
          @click="toggleAddItemModal"
        >
          <PlusIcon class="w-5 h-5 text-gray-700 mr-1" />
          <UITextSmMedium class="text-gray-700">{{
            $t('common.addAnItem')
          }}</UITextSmMedium>
        </div>
      </div>
      <div class="mx-4 mt-4" v-else>
        <div class="w-full h-[48.398px]"></div>
      </div>
      <div
        :class="`
           ${showPaymentDepositSchedule ? 'min-w-[50%]' : 'min-w-[33.333%]'}
        `"
        class="flex alignment-right float-right flex-col"
      >
        <div class="flex flex-col border-t mt-9 pt-6 border-b pb-6 mb-4">
          <div class="flex text-neutral-900">
            <UITextSmMedium class="py-2 flex-1 flex flex-col">
              {{ $t('common.subtotal') }}
            </UITextSmMedium>
            <UITextSmRegular class="p-2 flex-1 flex flex-col text-right"
              >{{ formatCurrency(subTotal, productCurrencySymbol) }}
            </UITextSmRegular>
          </div>
          <div
            v-if="!hasDiscount && builderStore.isDocumentEditable"
            class="flex"
          >
            <div
              class="cursor-pointer py-2 flex-1 flex flex-row text-primary-700 items-center font-semibold hover:border-b-blue-700"
              @click="toggleDiscountModal"
            >
              <Ticket01Icon class="w-5 h-5 mr-1 text-primary-700" />
              {{ $t('common.addDiscount') }}
            </div>
          </div>
          <div
            v-if="hasDiscount"
            class="flex cursor-pointer"
            @click="toggleDiscountModal"
          >
            <UITextSmMedium class="py-2 flex-1 flex flex-col"
              >{{ $t('common.discount') }}
              {{
                discountType === DiscountType.PERCENTAGE
                  ? `(${discountValue}%)`
                  : ''
              }}
            </UITextSmMedium>
            <UITextSmRegular class="p-2 flex-1 flex flex-col text-right"
              >- {{ formatCurrency(totalDiscount, productCurrencySymbol) }}
            </UITextSmRegular>
          </div>
          <div
            v-if="discountValue <= 0 && !builderStore.isDocumentEditable"
            style="height: 38.4px"
          ></div>
          <div class="flex" v-for="tax in appliedTax" v-bind:key="tax._id">
            <UITextSmRegular class="py-2 flex-1 flex flex-col"
              >{{ tax.name }}
            </UITextSmRegular>
            <UITextSmRegular class="p-2 flex-1 flex flex-col text-right"
              >{{ formatCurrency(tax.tax, productCurrencySymbol) }}
            </UITextSmRegular>
          </div>
        </div>
        <div class="flex text-neutral-900">
          <UITextMdMedium class="py-2 flex-1 flex flex-col">
            {{ $t('common.amountDue') }}
          </UITextMdMedium>
          <UITextMdMedium class="p-2 text-right flex-1 flex flex-col"
            >{{ formatCurrency(amountDue, productCurrencySymbol) }}
          </UITextMdMedium>
        </div>

        <div
          v-if="showPaymentDepositSchedule && enablePaymentDeposit"
          class="pr-2 pt-2"
        >
          <UIDivider
            :titlePlacement="'center'"
            :dashed="false"
            :vertical="false"
          />
          <div class="flex items-center py-2">
            <UITextSmMedium class="pr-2">
              {{ $t('paymentSchedule.label') }}
            </UITextSmMedium>
            <UITooltip :placement="'top'">
              <template #trigger>
                <InfoCircleIcon class="w-4 h-4 text-gray-500" />
              </template>
              {{
                $t('paymentSchedule.paymentDepositScheduleInfo', {
                  count: paymentDepositScheduleInfoCount,
                })
              }}
            </UITooltip>
          </div>
          <PaymentScheduleSummary
            class="pt-2"
            :paymentScheduleInfo="productListStore.paymentDepositSchedule"
            :currencyCode="currencyCode"
            :total="grandTotal"
            :isOuterBorder="false"
            :themeType="'horizontal'"
            :currency-list="productListStore.currencyDetails?.currency"
          />
          <div class="flex items-center pt-2">
            <UITextSmMedium class="py-2 flex-1 flex flex-col">
              {{ $t('paymentSchedule.totalAmountDue') }}
            </UITextSmMedium>
            <UITextSmRegular class="flex-1 flex flex-col text-right font-bold">
              {{ formatCurrency(grandTotal, productCurrencySymbol) }}
            </UITextSmRegular>
          </div>
        </div>

        <div v-if="isLastProductList && enablePaymentDeposit">
          <PaymentDepositSchedule
            v-if="enablePaymentDeposit"
            class="pt-2"
            :locationId="appStore.document.locationId"
            :paymentScheduleInfo="productListStore.paymentDepositSchedule"
            :total="grandTotal"
            :currencyCode="currencyCode"
            :disable="!builderStore.isDocumentEditable"
            @payment-schedule-updated="updatePaymentSchedule"
            @payment-schedule-removed="removePaymentSchedule"
          />
        </div>
      </div>
    </div>
    <!-- <div v-if="productBuilderOptions.lineItems.length > 0">
      <div class="flex w-full justify-center divider-box">
        <UIDivider vertical />
      </div>
      <PaymentScheduleTable :elementId="elementId" />
    </div> -->
  </div>

  <template>
    <AddNewProductItemModal
      :loading="loading"
      :productList="productList"
      :show="showModal"
      @addLineItemToList="onSubmitAddItem"
      @closeAddItemModal="toggleAddItemModal"
      @openAddProductModal="openAddNewProductModal"
    />
    <DiscountModal
      :elementId="elementId"
      :show="showDiscountModal"
      @closeDiscountModal="toggleDiscountModal"
    />
    <AddNewProductModal
      :show="showAddNewProductModal"
      @addNewProductToProductsList="addNewProductToProductsList"
      @closeAddProductModal="toggleAddNewProductModal"
    />
  </template>
</template>

<style lang="scss">
.divider-box {
  div[role='separator'] {
    height: 2em;
  }
}
</style>
