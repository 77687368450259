<script lang="ts" setup>
import { UIAvatar, UITag, getColorFromString } from '@gohighlevel/ghl-ui'
import { useAppStore } from '@/store/app'
import { PropType } from 'vue'
import {
  IRecipient,
  RecipientRolesEnum,
  useTypography,
  recipientThumbnail,
} from '@gohighlevel/ghl-proposals-common'
import { usePostmate } from '@/composition/usePostmate'
import { useRoute } from 'vue-router'
import { UITextXsRegular, UIPopover } from '@gohighlevel/ghl-ui'

const { toCapitalize } = useTypography()
const { redirectOnParentRoute } = usePostmate()
const appStore = useAppStore()
const route = useRoute()

const props = defineProps({
  recipient: { type: Object as PropType<IRecipient> },
  showRecipientTag: { type: Boolean, default: true },
})

const onClickCustomer = () => {
  if (props.recipient?.id) {
    const contactId = props.recipient?.id
    redirectOnParentRoute(
      {
        name: 'contact_detail-v2',
        params: {
          locationId: route.params.locationId as string,
          contactId: contactId,
        },
      },
      { newTab: true }
    )
  }
}
</script>

<template>
  <div class="flex" v-if="recipient?.id">
    <div class="flex p-2 cursor-pointer">
      <div class="w-10 mr-4">
        <UIAvatar
          :style="{
            backgroundColor: getColorFromString(
              toCapitalize(
                `${recipient?.contactName}` ||
                  `${recipient.firstName} ${recipient.lastName}`
              ).trim()
            ),
          }"
        >
          {{ recipientThumbnail(recipient.firstName, recipient.lastName) }}
        </UIAvatar>
      </div>
      <div class="flex flex-col">
        <div class="text-sm w-40">
          <div class="text-gray-700 font-semibold truncate capitalize">
            <a
              v-if="recipient.entityName === 'contacts'"
              class="cursor-pointer hover:text-blue-600"
              @click.prevent="onClickCustomer()"
            >
              {{ recipient.firstName }} {{ recipient.lastName }}
              {{ appStore.userId === recipient.id ? $t('common.you') : '' }}
            </a>
            <a v-else class="pointer-events-none">
              {{ recipient.firstName }} {{ recipient.lastName }}
              {{ appStore.userId === recipient.id ? $t('common.you') : '' }}
            </a>
          </div>
        </div>
        <div class="flex items-end text-gray-500 font-light w-40">
          <UIPopover
            trigger="hover"
            content-style="background-color: rgb(3 7 18)"
            arrow-style="background-color: rgb(3 7 18)"
            style="padding: 0"
          >
            <template #trigger>
              <UITextXsRegular class="truncate w-40">
                {{ recipient.email }}
              </UITextXsRegular>
            </template>
            <div class="font-light text-neutral-50 p-2">
              <UITextXsRegular class="capitalize">
                {{ recipient.firstName }} {{ recipient.lastName }}
                {{ appStore.userId === recipient.id ? $t('common.you') : '' }}
              </UITextXsRegular>
              <UITextXsRegular>
                {{ recipient.email }}
              </UITextXsRegular>
            </div>
          </UIPopover>
        </div>
        <div class="flex gap-2 mt-2" v-if="showRecipientTag">
          <UITag
            v-if="recipient.isPrimary"
            type="success"
            size="small"
            class="text-[8px]"
          >
            {{ $t('common.primary') }}
          </UITag>
          <div v-if="recipient?.role" class="flex grow gap-x-2">
            <UITag
              class="text-[8px]"
              size="small"
              v-if="recipient?.role === RecipientRolesEnum.SIGNER"
              :type="recipient.imgUrl ? 'success' : 'error'"
            >
              {{ recipient.imgUrl ? $t('common.signed') : $t('common.signer') }}
            </UITag>
            <UITag
              class="text-[8px]"
              size="small"
              v-if="recipient?.role === RecipientRolesEnum.USER"
              :type="recipient.hasCompleted ? 'success' : 'error'"
            >
              {{
                recipient.hasCompleted
                  ? $t('common.accepted')
                  : $t('common.needToAccept')
              }}
            </UITag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
