<script setup lang="ts">
import { useActionBarStore } from '@/store/actionBar'
import { PriceTypeEnum } from '@gohighlevel/ghl-proposals-common'

import {
  UIDrawer,
  UIDrawerContent,
  UITextMdMedium,
  UIRadioGroup,
  UIRadioGroupItem,
  UISpace,
  UITextSmMedium,
  UIDivider,
  UITextSmRegular,
} from '@gohighlevel/ghl-ui'
import { useI18n } from 'vue-i18n'
import OneTimePaymentSettings from './OneTimePaymentSettings.vue'
import RecurringPaymentSettings from './RecurringPaymentSettings.vue'
import { useProductListStore } from '@/store/productListStore'
import { computed } from 'vue'
import { useBuilderStore } from '@/store/builder'

const { t } = useI18n()
const productListStore = useProductListStore()
const storeAction = useActionBarStore()
const builderStore = useBuilderStore()

const disabled = computed(() => !builderStore.isDocumentEditable)

const radioOptions = computed(() => [
  {
    id: PriceTypeEnum.ONETIME,
    title: t('common.onetime'),
    disabled: disabled.value,
  },
  {
    id: PriceTypeEnum.RECURRING,
    title: t('common.recurring'),
    disabled:
      (!builderStore.hasNoLineItems() && !builderStore.isRecurringProduct()) ||
      disabled.value,
  },
])
const onInvoiceCategoryTypeUpdate = (value: PriceTypeEnum) => {
  productListStore.setInvoiceType(value)
}
function onClose() {
  storeAction.togglePaymentSettings()
}
</script>

<template>
  <UIDrawer
    :width="380"
    id="payment-settings-drawer"
    :show="storeAction.paymentSettings"
    @update:show="onClose"
    :placement="'left'"
    to="#drawer-target"
    class="!shadow-page h-screen"
    :trap-focus="false"
  >
    <UIDrawerContent
      id="payment-settings-drawer-content"
      :title="$t('common.paymentSettings')"
    >
      <UITextMdMedium class="mb-2">{{
        $t('common.invoiceType')
      }}</UITextMdMedium>
      <UITextSmRegular class="text-gray-700 mb-1">{{
        $t('common.recurringInvoiceChargeDescription')
      }}</UITextSmRegular>
      <UITextSmRegular class="text-gray-700 mb-1">{{
        $t('common.onetimeInvoiceChargeDescription')
      }}</UITextSmRegular>

      <UIRadioGroup
        :value="productListStore.invoiceType"
        name="invoiceType"
        class="mt-6 flex"
        @update:value="onInvoiceCategoryTypeUpdate"
      >
        <UISpace
          inline
          size="small"
          class="!flex-nowrap !justify-between flex-1"
        >
          <UIRadioGroupItem
            class="invoice-type-radio"
            v-for="item in radioOptions"
            :value="item.id"
            :disabled="item.disabled"
            v-bind:key="item.id"
          >
            <template #content>
              <UITextSmMedium>{{ item.title }}</UITextSmMedium>
            </template>
          </UIRadioGroupItem>
        </UISpace>
      </UIRadioGroup>
      <div class="-mx-6">
        <UIDivider class="mt-6" />
      </div>
      <RecurringPaymentSettings
        :disabled="disabled"
        v-if="productListStore.invoiceType === PriceTypeEnum.RECURRING"
      />
      <OneTimePaymentSettings />
    </UIDrawerContent>
  </UIDrawer>
</template>

<style lang="scss">
.invoice-type-radio {
  .hl-radio-group-item-container {
    flex-direction: row-reverse;
  }
}
#payment-settings-drawer {
  .hl-drawer-content {
    height: calc(100vh - 164px);
  }
}
</style>
