<script lang="ts" setup>
import { PlusIcon } from '@gohighlevel/ghl-icons/24/outline'
import { UISelect } from '@gohighlevel/ghl-ui'
import type { SelectType } from '@gohighlevel/ghl-ui/dist/types/components/select/Select.vue'
import { SelectOption } from 'naive-ui'
import { PropType, computed, ref } from 'vue'
import { string } from 'yup'

const emit = defineEmits([
  'update:value',
  'search',
  'clearQuery',
  'scroll',
  'blur',
  'add',
])

const props = defineProps({
  label: String,
  id: String,
  options: Array<SelectOption>,
  placeholder: String,
  multiple: Boolean,
  value: [String, Array<string>] as PropType<string[] | string>,
  tag: Boolean,
  clearable: Boolean,
  loading: Boolean,
  remote: Boolean,
  maxTagCount: Number,
  type: String as PropType<SelectType>,
  validationType: String,
  disableCreate: Boolean,
  enableAdd: Boolean,
  labelClass: Object as PropType<any>,
  disableUpdate: Boolean,
  disabled: Boolean,
})

const searchQuery = ref('')
const selectRef = ref()

function onSelect(event: string | string[]) {
  if (props.disableUpdate) return
  emit('update:value', event, props.options)
}

function onSearch(data: string) {
  searchQuery.value = data
  emit('search', data)
}

function clearAll() {
  searchQuery.value = ''
  emit('clearQuery')
  emit('blur')
}

function handleScroll(e: Event) {
  const currentTarget = e.currentTarget as HTMLElement
  if (
    currentTarget.scrollTop + currentTarget.offsetHeight >=
    currentTarget.scrollHeight - 10
  ) {
    emit('scroll')
  }
}

function handleCreate(newValue: string) {
  if (props.disableCreate) {
    return false
  }
  if (props.validationType === 'email') {
    const emailValidator = string().email()
    let isValidEmail = true
    try {
      emailValidator.validateSync(newValue)
    } catch {
      isValidEmail = false
    }
    if (isValidEmail) {
      const option = {
        value: newValue,
        label: newValue,
      }
      return option
    }
    return false
  } else {
    const option = {
      value: newValue,
      label: newValue,
    }
    return option
  }
}

function handleAdd() {
  emit('add', searchQuery.value)
  searchQuery.value = ''
  emit('clearQuery')
}

const notInOptions = computed(() => {
  return !props.options?.some(option => option.value === searchQuery.value)
})
</script>

<template>
  <div class="flex items-center w-full" ref="selectRef">
    <div
      v-if="props.label"
      class="text-gray-500 text-sm whitespace-nowrap pr-2 w-1/12"
      :class="labelClass"
    >
      {{ props.label }}:
    </div>
    <UISelect
      :id="props.id"
      :value="props.value"
      :options="props.options"
      :placeholder="props.placeholder || ' '"
      :multiple="props.multiple"
      :tag="props.tag"
      filterable
      :clearable="props.clearable"
      :remote="props.remote"
      :loading="props.loading"
      :max-tag-count="props.maxTagCount"
      @update:value="onSelect"
      @search="onSearch"
      :on-blur="clearAll"
      @scroll="handleScroll"
      @create="handleCreate"
      :type="props.type"
      size="small"
      class="text-gray-700 no-border-select w-11/12"
      :disabled="props.disabled"
    >
      <template #empty>
        <div class="flex justify-start w-full text-gray-300">
          <div v-if="searchQuery">
            No matching result for
            <span class="font-semibold">“{{ searchQuery }}” </span>
          </div>
          <div v-else>No Data</div>
        </div>
      </template>
      <template #action v-if="props.enableAdd && searchQuery && notInOptions">
        <div @click="handleAdd" class="flex items-center cursor-pointer">
          <PlusIcon class="h-[10px] mr-1 text-primary-700" />
          <span>
            {{ searchQuery }}
          </span>
        </div>
      </template>
    </UISelect>
  </div>
</template>

<style scoped>
.no-border-select.n-select,
.no-border-select .n-base-selection__border,
.no-border-select .n-base-selection {
  --n-border: none !important;
  --n-border-radius: 0 !important;
  --border-hover: none !important;
  --border-focus: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  /* --n-height: 24px !important; */
  --n-font-size: 13px !important;
}

.no-border-select.n-select--focus {
  color: unset !important;
  background-color: transparent !important;
}

.no-border-select .n-base-selection-input__content {
  @apply text-gray-700;
}

.no-border-select .n-base-selection__state-border {
  @apply hidden;
}
.no-border-select input {
  box-shadow: unset;
}
::v-deep(.n-select .n-base-selection) {
  --n-height: 35px !important;
}

::v-deep(.n-base-selection-tags) {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.n-base-selection-tags {
  overflow: hidden; /* Hides overflow content */
  text-overflow: ellipsis; /* Adds ellipsis for truncated text */
  white-space: nowrap; /* Prevents text from wrapping */
  display: inline-block; /* Ensures proper inline truncation */
  max-width: 100%; /* Optional: Restrict the width to the container size */
}
</style>
