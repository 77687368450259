<script setup lang="ts">
import { ref } from 'vue'
const props = defineProps({
  isActive: Boolean,
  showIndicator: Boolean,
})

const emits = defineEmits(['click'])

const isActive = ref(props.isActive)

function handleClick() {
  isActive.value = !isActive.value
  emits('click')
}
</script>

<template>
  <div
    class="relative text-gray-700 rounded cursor-pointer hover:bg-gray-100"
    :class="{
      'bg-gray-200': isActive,
      'font-bold text-primary-color': props.showIndicator,
    }"
    @click="handleClick"
  >
    <div class="w-full flex items-center justify-center">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.text-primary-color {
  color: #155eef;
}
</style>
