<script lang="ts" setup>
import DrawerPages from '@/components/drawer/DrawerPages.vue'
import Elements from '@/components/drawer/DroppableElements.vue'
import PagesLoop from '@/components/editor/PagesLoop.vue'
import { useBuilderStore } from '@/store/builder'
import { UISpin } from '@gohighlevel/ghl-ui'
import { computed, onMounted, ref, watch } from 'vue'
import DrawerRecipients from '../components/drawer/DrawerRecipients/DrawerRecipients.vue'
import DrawerSettings from '../components/drawer/DrawerSettings.vue'
import DrawerVariable from '../components/drawer/DrawerVariable.vue'
import DrawerDocumentSettings from '../components/drawer/DrawerDocumentSettings.vue'
import DrawerPaymentSettings from '../components/drawer/DrawerPaymentSettings/DrawerPaymentSettings.vue'
import EditorMainHeader from '../components/header-components/EditorMainHeader.vue'
import EditorSubHeading from '../components/header-components/EditorSubHeading.vue'
import StyleManager from '../components/styles-manager/StyleManager.vue'
import { useActionBarStore } from '@/store/actionBar'
import { useDocument } from '@/composition'
import { useAppStore } from '@/store/app'
import { usePostmate } from '@/composition/usePostmate'
import { useUsers } from '@/composition/useUsers'
import { usePayment } from '@/composition/usePayment'
import HiddenDroppableElements from '@/components/drawer/HiddenDroppableElements.vue'
import {
  useCustomValues,
  useCustomFieldsForLinkage,
} from '@gohighlevel/ghl-proposals-common'
import { useRoute } from 'vue-router'

const route = useRoute()
const pageLoading = ref(false)
const builderStore = useBuilderStore()
const appStore = useAppStore()
const store = useActionBarStore()
const { fetchUser } = useUsers()
const { getCustomFieldsForLinkage } = useCustomFieldsForLinkage(route)
const { getCustomValuesAndFieldsForLocation } = useCustomValues()

const { getCurrencyDetails } = usePayment()

const { loadDocument, loading, fetchAllCustomValues } = useDocument()
const postmate = usePostmate()
const isRouteChangeConfirmed = computed(
  () => postmate.isRouteChangeConfirmed.value
)
const hasUnSavedChanges = computed(() => appStore.unsavedChanges)
if (process.env.NODE_ENV === 'development') {
  // Postmate.debug = true;
}

const loadData = async () => {
  await loadDocument().then(() => {
    fetchAllCustomValues()
  })
}

const fetchLoggedUserData = async () => {
  const userData = await fetchUser(appStore.userId)
  appStore.updateLoggedInUserData(userData)
}

function alertHandler(e: any) {
  e.preventDefault()
  e.returnValue = ''
}

const bindWindowUnLoadEvent = () => {
  window.addEventListener('beforeunload', alertHandler)
}
const removeWindowUnLoadEvent = () => {
  window.removeEventListener('beforeunload', alertHandler)
}
onMounted(async () => {
  pageLoading.value = true
  await loadData()
  await getCurrencyDetails()
  await fetchLoggedUserData()
  await getCustomValuesAndFieldsForLocation()
  await getCustomFieldsForLinkage()
  pageLoading.value = false
})

watch([hasUnSavedChanges, isRouteChangeConfirmed], () => {
  if (hasUnSavedChanges.value && !isRouteChangeConfirmed.value) {
    bindWindowUnLoadEvent()
  } else {
    removeWindowUnLoadEvent()
  }
})
</script>

<template>
  <div
    v-if="pageLoading"
    class="flex justify-center items-center justify-items-center h-screen"
  >
    <UISpin :show="pageLoading" />
  </div>
  <template v-else>
    <div
      v-if="loading"
      class="flex justify-center items-center justify-items-center h-screen absolute w-screen z-[99999] bg-white bg-opacity-80"
    >
      <UISpin :show="true" />
    </div>
    <EditorMainHeader />
    <div>
      <EditorSubHeading />
      <div class="flex w-full editor-area relative">
        <div id="drawer-target"></div>
        <DrawerVariable />
        <DrawerSettings />
        <DrawerPages />
        <Elements />
        <DrawerRecipients />
        <DrawerPaymentSettings />
        <DrawerDocumentSettings />
        <HiddenDroppableElements />
        <div class="flex w-full" @click="store.closeDrawer()">
          <PagesLoop
            class="page-loop flex flex-col items-center overflow-auto elements_page_parent flex-1 bg-gray-100 pb-8"
          />
        </div>
        <template v-if="builderStore.isDocumentEditable">
          <div id="active-element-drawer-target"></div>
          <StyleManager class="w-96 overflow-y-auto" />
        </template>
      </div>
    </div>
  </template>
</template>

<style lang="scss">
.proposal-editor {
  display: inline-flex;
  height: 20rem;
}
.editor-area {
  height: calc(100vh - 110px);
}

.proposal-editor {
  display: inline-flex;
  height: 20rem;
}
.elements_page_parent {
  padding-top: 1rem;
  margin-bottom: 1rem;
}
</style>
