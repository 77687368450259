<script setup lang="ts">
import config from '@/config'
import { Ref, computed, onMounted, ref } from 'vue'
import { useAppStore } from '@/store/app'
import { useDocument } from '@/composition'
import {
  SentMedium,
  RecipientRolesEnum,
  useCustomNotificationSettings,
} from '@gohighlevel/ghl-proposals-common'
import {
  UIButton,
  UITextMdNormal,
  UIInput,
  UITag,
  UIInputGroup,
  UIDivider,
  UITextLgMedium,
  UITextSmNormal,
} from '@gohighlevel/ghl-ui'
import {
  Link02Icon,
  Copy01Icon,
  CheckIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import { useEmitter } from '@/composable/useEmitter'
import CustomModal from '@/components/common/CustomModal.vue'
import UserAvatar from '@/components/common/UserAvatar.vue'
import { debounce } from 'lodash'
import { useRoute } from 'vue-router'

const route = useRoute()
const { saveDocument, sendDocument } = useDocument()
const { fetchCustomNotificationSetting, notificationSetting } =
  useCustomNotificationSettings(route.params.locationId as string)

const generateLinkModal = ref(false)
const loadingGenerateLinks = ref(false)
const showCopyIcons: Ref<string> = ref('')
const appStore = useAppStore()
const emitter = useEmitter()

onMounted(() => {
  fetchCustomNotificationSetting()
  emitter.on('open-link-generation-model', () => {
    generateLinkModal.value = true
    loadingGenerateLinks.value = false
  })
})

const fetchCCRecipients = () => {
  const ccRecipients =
    appStore.document?.ccRecipients?.map(({ _id, ...rest }) => rest) || []
  if (ccRecipients.length > 0) {
    return ccRecipients
  } else if (
    appStore.document?.documentRevision === 1 &&
    ccRecipients.length === 0
  ) {
    const ccRecipientsFromSettings =
      notificationSetting.value.ccRecipients?.map(({ _id, ...rest }) => rest) ||
      []
    return ccRecipientsFromSettings
  } else {
    return []
  }
}

const links = computed(() => {
  return appStore?.linkReference
})

const whiteLabelBaseUrl = computed(() => {
  return appStore?.whiteLabelViewerBaseUrl
})

const recipients = computed(() => {
  return appStore.document?.recipients
})

const toggleLinkModal = () => {
  generateLinkModal.value = !generateLinkModal.value
}

const getBaseURL = () => {
  if (process.env.NODE_ENV === 'development') {
    return config.DOCUMENT_VIEWER_URL
  }
  return whiteLabelBaseUrl.value || config.DOCUMENT_VIEWER_URL
}
const linkLookup = (recipientId: string, entityName: string | undefined) => {
  if (recipientId && entityName) {
    const linkReference = links.value.find((elem: any) => {
      return elem.recipientId === recipientId && elem.entityName === entityName
    })
    const referenceId = linkReference?.referenceId ?? '..'
    const baseUrl = getBaseURL()
    return (
      baseUrl + '/documents/v1/' + referenceId + `?locale=${appStore.locale}`
    )
  }
}

const copyLinkReference = (
  recipientId: string,
  entityName: string | undefined
) => {
  showCopyIcons.value = ''
  const rawIdentifier = `${recipientId}_${entityName}`
  const linkReference = linkLookup(recipientId, entityName)
  navigator.clipboard.writeText(linkReference ?? '')
  showCopyIcons.value = rawIdentifier
  if (showCopyIcons.value) {
    debounce(() => {
      if (showCopyIcons.value === rawIdentifier) {
        showCopyIcons.value = ''
      }
    }, 1200)()
  }
}

const isLinkCopied = (recipientId: string, entityName: string | undefined) => {
  return showCopyIcons.value === `${recipientId}_${entityName}`
}

const generateDynamicLinksAndSendDocument = async () => {
  try {
    loadingGenerateLinks.value = true
    const ccRecipients = fetchCCRecipients()
    await saveDocument()
    await sendDocument(SentMedium.LINK, {
      ccRecipients,
    })
  } catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <CustomModal
    :width="850"
    :show="generateLinkModal"
    :modalType="'primary'"
    :title="$t('proposals.sendLink.shareLink')"
    @on-cancel="toggleLinkModal"
    id="generate-link-modal"
    :cancelActionText="''"
    :successActionText="''"
    class="generate-link-modal"
    :headerIcon="false"
  >
    <div class="flex flex-col mt-[-10px]">
      <div
        class="flex flex-row bg-primary-50 rounded items-center justify-center px-2 py-3"
        v-if="links.length === 0"
      >
        <div class="flex-none w-25">
          <img src="/link_generate.png" />
        </div>
        <div class="flex-1 p-4">
          <UITextLgMedium>
            {{ $t('proposals.sendLink.title') }}
          </UITextLgMedium>
          <UIButton
            type="primary"
            id="generate-link-btn"
            class="p-3 my-2"
            @click="generateDynamicLinksAndSendDocument"
            :loading="loadingGenerateLinks"
            :disabled="loadingGenerateLinks"
            ><Link02Icon class="w-5 h-5 mr-2" />
            {{ $t('proposals.sendLink.generateLink') }}</UIButton
          >
          <UITextMdNormal class="text-gray-500">
            {{ $t('proposals.sendLink.markedAsSent') }}
            <b
              ><u> {{ $t('proposals.sendLink.sent') }}</u></b
            >
          </UITextMdNormal>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="bg-gray-100 rounded justify-center mt-6 p-3 mb-3">
          {{ $t('proposals.sendLink.description') }}
        </div>
      </div>
      <div class="flex flex-col">
        <div
          v-for="recipient in recipients"
          :key="recipient.id"
          :class="links.length === 0 ? 'is-disabled' : ''"
        >
          <div class="mt-6 recipient-link">
            <div class="flex">
              <div class="w-4/12">
                <UserAvatar
                  :recipient="recipient"
                  :show-recipient-tag="false"
                />
              </div>
              <div class="w-2/12">
                <div class="flex-1 items-center p-2">
                  <UITag
                    v-if="recipient.isPrimary"
                    type="success"
                    size="small"
                    class="text-[8px] mx-1"
                  >
                    {{ $t('common.primary') }}
                  </UITag>
                  <UITag
                    class="text-[8px] mx-1"
                    size="small"
                    v-if="recipient?.role === RecipientRolesEnum.SIGNER"
                    :type="recipient.imgUrl ? 'success' : 'error'"
                  >
                    {{
                      recipient.imgUrl
                        ? $t('common.signed')
                        : $t('common.signer')
                    }}
                  </UITag>
                  <UITag
                    class="text-[8px]"
                    size="small"
                    v-if="recipient?.role === RecipientRolesEnum.USER"
                    :type="recipient.hasCompleted ? 'success' : 'error'"
                  >
                    {{
                      recipient.hasCompleted
                        ? $t('common.accepted')
                        : $t('common.needToAccept')
                    }}
                  </UITag>
                </div>
              </div>
              <div class="w-6/12">
                <div class="flex-1">
                  <UIInputGroup>
                    <UIInput
                      readonly
                      class="bg-gray-50"
                      id="recipient-lin-input"
                      :value="linkLookup(recipient.id, recipient.entityName)"
                    />
                    <UIButton
                      id="recipient-copy-btn"
                      @click="
                        copyLinkReference(recipient.id, recipient.entityName)
                      "
                      ><Copy01Icon
                        class="w-4"
                        v-if="!isLinkCopied(recipient.id, recipient.entityName)"
                      />
                      <CheckIcon
                        class="w-4 text-emerald-500"
                        v-if="isLinkCopied(recipient.id, recipient.entityName)"
                      />

                      <UITextSmNormal
                        class="ml-1"
                        :class="{
                          'text-emerald-500': isLinkCopied(
                            recipient.id,
                            recipient.entityName
                          ),
                        }"
                        >{{
                          !isLinkCopied(recipient.id, recipient.entityName)
                            ? $t('common.copy')
                            : $t('common.copied')
                        }}</UITextSmNormal
                      ></UIButton
                    >
                  </UIInputGroup>
                </div>
              </div>
            </div>
            <UIDivider class="mb-0" />
          </div>
        </div>
      </div>
    </div>
  </CustomModal>
</template>
