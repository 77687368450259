import {
  DownloadPdfParams,
  GetDocument,
  GetDocumentVersions,
  IDocument,
  LinkReference,
  ProposalPayment,
  RestoreDocument,
  SaveAsTemplateParams,
  SendDocumentParams,
  UpdateDocument,
  UpdateDocumentStatusParams,
} from '@gohighlevel/ghl-proposals-common'

import config from '@/config'
import { requests } from '.'

export const DocumentServices = {
  getDocument: (params: GetDocument) =>
    requests(config.PROPOSAL_SERVICE_URL).get<{
      document: IDocument
      links?: LinkReference[]
      whiteLabelBaseUrl?: string
      paymentInfo?: ProposalPayment
      invoiceWhiteLabelBaseUrl?: string
    }>(`/document/${params.id}`, {
      params: { locationId: params.locationId },
    }),
  updateDocument: (params: UpdateDocument) => {
    const { id, ...rest } = params
    return requests(config.PROPOSAL_SERVICE_URL).put(`/document/${id}`, rest)
  },
  getDocumentVersions: (params: GetDocumentVersions) =>
    requests(config.PROPOSAL_SERVICE_URL).get('/builder/versions', { params }),
  restoreDocumentVersion: (params: RestoreDocument) =>
    requests(config.PROPOSAL_SERVICE_URL).post(
      '/builder/restoreVersion',
      params
    ),
  sendDocument: (params: SendDocumentParams) =>
    requests(config.PROPOSAL_SERVICE_URL).post('/document/send', params),
  updateDocumentStatus: (params: UpdateDocumentStatusParams) =>
    requests(config.PROPOSAL_SERVICE_URL).put('/document/status', params),
  removeDocument: (id: string) =>
    requests(config.PROPOSAL_SERVICE_URL).delete(`/document/${id}`),
  downloadPdf: (params: DownloadPdfParams) =>
    requests(config.PROPOSAL_SERVICE_URL).post('/document/download', params),
  saveAsTemplate: (params: SaveAsTemplateParams) =>
    requests(config.PROPOSAL_SERVICE_URL).post(
      `/templates/to-template/${params.locationId}/${params.documentId}`
    ),
  getAllCustomVariables: (
    locationId: string,
    documentId: string,
    contactId?: string
  ) =>
    requests(config.PROPOSAL_SERVICE_URL).get<Record<string, string>>(
      `/document/custom-values/${locationId}/${documentId}` +
        (contactId ? `?contactId=${contactId}` : '')
    ),
  getDocumentMetaInfo: (params: { locationId: string; documentId: string }) =>
    requests(config.PROPOSAL_SERVICE_URL).get('/document/meta', { params }),
}
