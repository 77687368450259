import { ref } from 'vue'

const pagePreviewMap = ref(new Map<string, HTMLCanvasElement | null>())
export const usePagePreview = () => {
  /**
   * Generate or retrieve a cached thumbnail for the given element ID
   * @param id - The ID of the element to render
   * @returns {<string>} - A base64 data URL of the thumbnail
   */
  const getThumbnail = (id: string) => {
    if (pagePreviewMap.value.has(id)) {
      return pagePreviewMap.value.get(id)
    }
  }

  /**
   * Clear the cache for a specific ID or all entries
   * @param id - Optional ID to clear. If not provided, clears all cache.
   */
  const clearCache = (id?: string) => {
    if (id) {
      pagePreviewMap.value.delete(id)
    } else {
      pagePreviewMap.value.clear()
    }
  }

  /**
   * Manually set a thumbnail in the cache
   * @param id - The ID for the thumbnail
   * @param dataUrl - The base64 data URL of the thumbnail
   */
  const setThumbnail = (
    id: string,
    dataUrl: string | null | HTMLCanvasElement
  ) => {
    pagePreviewMap.value.set(id, dataUrl)
  }

  return {
    setThumbnail,
    getThumbnail,
    clearCache,
    pagePreviewMap,
  }
}
