import { IElement } from '@gohighlevel/ghl-proposals-common'
import { defineStore } from 'pinia'

export interface ActionBarStore {
  pagesActive: boolean
  elementsActive: boolean
  settingsActive: boolean
  styleManagerActive: boolean
  documentVariableActive: boolean
  recipientsActive: boolean
  dragStateIsActivated: boolean
  paymentSettings: boolean
  documentSettings: boolean
  lastActiveDragElement: IElement | null
  draggingFloatingElement: boolean
}

export const useActionBarStore = defineStore('actionBar', {
  state: (): ActionBarStore => ({
    pagesActive: false,
    elementsActive: false,
    settingsActive: false,
    styleManagerActive: false,
    documentVariableActive: false,
    recipientsActive: false,
    dragStateIsActivated: false,
    paymentSettings: false,
    documentSettings: false,
    lastActiveDragElement: null,
    draggingFloatingElement: false,
  }),
  actions: {
    togglePages() {
      this.pagesActive = !this.pagesActive
      if (this.pagesActive) {
        this.elementsActive = false
        this.settingsActive = false
        this.documentVariableActive = false
        this.recipientsActive = false
        this.paymentSettings = false
        this.documentSettings = false
      }
    },
    toggleElements() {
      this.elementsActive = !this.elementsActive
      if (this.elementsActive) {
        this.pagesActive = false
        this.settingsActive = false
        this.documentVariableActive = false
        this.recipientsActive = false
        this.paymentSettings = false
        this.documentSettings = false
      }
    },
    toggleSettings() {
      this.settingsActive = !this.settingsActive
      if (this.settingsActive) {
        this.pagesActive = false
        this.elementsActive = false
        this.documentVariableActive = false
        this.recipientsActive = false
        this.paymentSettings = false
        this.documentSettings = false
      }
    },
    toggleRecipients() {
      this.recipientsActive = !this.recipientsActive
      if (this.recipientsActive) {
        this.pagesActive = false
        this.elementsActive = false
        this.documentVariableActive = false
        this.settingsActive = false
        this.paymentSettings = false
        this.documentSettings = false
      }
    },
    toggleDocumentVariable() {
      this.documentVariableActive = !this.documentVariableActive
      if (this.documentVariableActive) {
        this.pagesActive = false
        this.elementsActive = false
        this.settingsActive = false
        this.recipientsActive = false
        this.paymentSettings = false
        this.documentSettings = false
      }
    },
    togglePaymentSettings() {
      this.paymentSettings = !this.paymentSettings
      if (this.paymentSettings) {
        this.pagesActive = false
        this.elementsActive = false
        this.settingsActive = false
        this.recipientsActive = false
        this.documentVariableActive = false
        this.documentSettings = false
      }
    },
    toggleDocumentSettings() {
      this.documentSettings = !this.documentSettings
      if (this.documentSettings) {
        this.pagesActive = false
        this.elementsActive = false
        this.settingsActive = false
        this.recipientsActive = false
        this.documentVariableActive = false
        this.paymentSettings = false
      }
    },
    toggleStyleManager() {
      this.styleManagerActive = !this.styleManagerActive
    },
    toggleDragState(status: boolean) {
      this.dragStateIsActivated = status
      this.closeDrawer()
    },
    closeDrawer() {
      this.elementsActive = false
      this.pagesActive = false
      this.documentVariableActive = false
      this.recipientsActive = false
      this.settingsActive = false
      this.paymentSettings = false
      this.documentSettings = false
    },
    setLastActiveDragElement(element: IElement | null) {
      this.lastActiveDragElement = element
    },
    setFloatingElementDragging(status: boolean) {
      this.draggingFloatingElement = status
    },
    setPaymentSettings(status: boolean) {
      this.paymentSettings = status
      if (this.paymentSettings) {
        this.pagesActive = false
        this.elementsActive = false
        this.settingsActive = false
        this.recipientsActive = false
        this.documentVariableActive = false
      }
    },
  },
})
