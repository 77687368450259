<script setup lang="ts">
import { useProductListStore } from '@/store/productListStore'
import { UITextSmRegular } from '@gohighlevel/ghl-ui'
import PriceUpdater from './PriceUpdater.vue'
import TaxList from './TaxList.vue'
import { PropType, computed, onMounted, ref, watch } from 'vue'
import { useTaxes } from '@/composition'
import {
  ITax,
  getMarginProperties,
  getPaddingProperties,
  onChangeMarginPadding,
} from '@gohighlevel/ghl-proposals-common'
import {
  IElement,
  ELEMENTS_STYLES,
  UIBuilderSpacing,
} from '@gohighlevel/ghl-proposals-common'
import { useElementOption } from '@/composable/elementOptions'
import ColorPickerComponent from '../../../common/ColorPickerComponent.vue'

const props = defineProps({
  element: Object as PropType<IElement>,
})

const productListStore = useProductListStore()
const activeRow = computed(() => productListStore.getActiveRow)
const { updateStyles } = useElementOption()
const taxes = ref<ITax[]>([])
const { fetchTaxes, loading } = useTaxes()

const bgColor = ref(props.element?.responsiveStyles?.large?.backgroundColor)
const margin = ref(getMarginProperties(props.element))
const padding = ref(getPaddingProperties(props.element))

watch(props, () => {
  if (props.element) {
    bgColor.value = props.element?.responsiveStyles?.large?.backgroundColor
    margin.value = getMarginProperties(props.element)
    padding.value = getPaddingProperties(props.element)
  }
})

onMounted(async () => {
  taxes.value = await fetchTaxes()
})

const onBoxStyleChange = onChangeMarginPadding(updateStyles)
</script>

<template>
  <div class="proposal-pages-scroll">
    <template v-if="activeRow?.key">
      <PriceUpdater :element="props.element" />
      <TaxList :loading="loading" :taxes="taxes" />
    </template>

    <div :class="`py-4 ${activeRow?.key ? 'border-t' : ''}`">
      <UITextSmRegular
        v-if="activeRow?.key"
        class="text-gray-700 font-medium block py-2"
      >
        {{ $t('common.styles') }}</UITextSmRegular
      >
      <ColorPickerComponent
        class="color-picker-container-1"
        v-model="bgColor"
        :label="$t('common.backgroundColor')"
        @update:modelValue="
          updateStyles(
            ELEMENTS_STYLES.BACKGROUND_COLOR,
            bgColor,
            props.element?.id,
            props.element?.type
          )
        "
      />

      <UIBuilderSpacing :element="props.element" @onUpdate="onBoxStyleChange" />
    </div>
  </div>
</template>
