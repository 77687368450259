import { Selection, TextSelection } from '@tiptap/pm/state'
import { Decoration, DecorationSet, EditorView } from '@tiptap/pm/view'
import { Plugin, PluginKey } from 'prosemirror-state'
import { addPseudoElementStyle, loadingAnimation, memoize } from './utility'

import { Extension } from '@tiptap/core'
import { isString } from 'lodash'

export interface MagicPlaceholderOptions {
  placeholderValues: Record<string, string>
  pattern?: string | RegExp
  loading?: boolean
}

export type MagicPlaceholderExtensionType = Extension<MagicPlaceholderOptions>

const addLoadingAnimation = memoize(loadingAnimation)
const addStyleToPseudoElement = memoize(addPseudoElementStyle)

addLoadingAnimation()
addStyleToPseudoElement(
  '.custom-value-placeholder.with-loader',
  'before',
  `content: "Loading...";position: absolute; top: 4px; color: #333; left: 4px; z-index: 1; font-size: 8px; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;`
)
// addStyleToPseudoElement(
//   '.custom-value-placeholder.with-loader',
//   'after',
//   `content: "";animation: loading 1s linear infinite; position: absolute; width: 100%; height: 100%; background-color: var(--warning-50); left: 0; top:0; background-size: 1rem 1rem; background-image: linear-gradient(45deg, rgb(255 188 0 / 15%) 25%, transparent 25%, transparent 50%, rgb(255 188 0 / 15%) 50%, rgb(255 188 0 / 15%) 75%, transparent 75%, transparent);`
// )

const pluginKey = new PluginKey('magic_custom_placeholder')

/**
 * Sanitizes text before showing in magic placeholder, current sanitization includes
 * 1. Removing backslashes that are not part of escape characters
 * @param str
 * @returns
 */
function sanitizePlaceholderText(str: string): string {
  if (!str || !isString(str)) return str

  return str.replace(/\\(?=["\\])/g, '')
}

/**
 * Represents the Magic Placeholder Extension.
 */
export const MagicPlaceholderExtension: MagicPlaceholderExtensionType =
  Extension.create({
    defaultOptions: {
      pattern: /{{\s*([^{}]+?)\s*}}/g,
      placeholderValues: {} as MagicPlaceholderOptions['placeholderValues'],
      loading: false,
    },

    name: 'custom_placeholder',

    /**
     * Adds ProseMirror plugins for the Custom Placeholder Extension.
     * @returns An array of ProseMirror plugins.
     */
    addProseMirrorPlugins() {
      const options = this.options
      const regex = options.pattern
      return [
        new Plugin({
          key: pluginKey,
          state: {
            init() {
              return DecorationSet.empty
            },
            apply(tr) {
              const { doc } = tr
              const decorations: Decoration[] = []
              doc.descendants((node, pos) => {
                if (node.isText) {
                  const text = node.text
                  let match: RegExpExecArray | null

                  while ((match = regex.exec(text as string)) !== null) {
                    const key = match[1].trim()
                    const matchRegex = match
                    if (
                      options.placeholderValues.hasOwnProperty.call(
                        options.placeholderValues,
                        key
                      )
                    ) {
                      const placeholderValue = sanitizePlaceholderText(
                        options.placeholderValues[key]
                      )
                      const start = pos + match.index
                      const end = start + match[0].length

                      if (placeholderValue !== '') {
                        const placeholderDecoration = Decoration.inline(
                          start,
                          end,
                          {
                            class: 'custom-value-placeholder-token',
                            'data-key': matchRegex[0],
                            style: 'display:none;',
                          },
                          {
                            inclusiveStart: false,
                            inclusiveEnd: false,
                            token: matchRegex[0],
                            key: matchRegex[0],
                          }
                        )

                        const widgetDecoration = Decoration.widget(
                          end,
                          () => {
                            const span = document.createElement('span')
                            span.classList.add('custom-value-placeholder')
                            if (options.loading) {
                              span.classList.add('with-loader')
                            } else {
                              span.classList.remove('with-loader')
                            }

                            let cssText = `background-color: var(--warning-50); outline: 1px ridge var(--warning-100); outline-offset: -1px; border-radius: 3px; position: relative;`
                            const loadingStyle = `
                              color: transparent;
                              animation: loading 1s linear infinite;
                              background-color: var(--warning-50);
                              background-size: 1rem 1rem;
                              background-image: linear-gradient(45deg, rgb(255 188 0 / 15%) 25%, transparent 25%, transparent 50%, rgb(255 188 0 / 15%) 50%, rgb(255 188 0 / 15%) 75%, transparent 75%, transparent);
                            `
                            if (options.loading) {
                              cssText = cssText + ' ' + loadingStyle
                            }
                            span.style.cssText = cssText

                            const lines =
                              placeholderValue && isString(placeholderValue)
                                ? placeholderValue.split('\\n')
                                : [placeholderValue]

                            if (lines) {
                              lines.forEach((line, index) => {
                                if (index > 0) {
                                  span.appendChild(document.createElement('br'))
                                }
                                span.appendChild(document.createTextNode(line))
                              })
                            }

                            span.setAttribute('data-key', key)
                            span.title = matchRegex[0]
                            return span
                          },
                          {
                            key: `widget-${matchRegex[0]}`,
                            side: -1,
                            ignoreSelection: true,
                          }
                        )

                        decorations.push(widgetDecoration)
                        decorations.push(placeholderDecoration)
                      }
                    }
                  }
                }
              })

              return DecorationSet.create(doc, decorations)
            },
          },
          props: {
            /**
             * Retrieves the decorations for the Custom Placeholder Extension.
             * @param state The current state of the editor.
             * @returns The decorations for the editor state.
             */
            decorations(state) {
              return this.getState(state)
            },
            /**
             * Handles the keydown event for the Custom Placeholder Extension.
             * @param view The editor view.
             * @param event The keydown event.
             * @returns A boolean indicating whether the event was handled.
             */
            handleKeyDown(view, event) {
              if (event.key === 'Backspace' || event.key === 'Delete') {
                const { state } = view
                const { selection } = state
                const decorations = this.getState(state)

                const pos = selection.$to.pos

                const decoration = decorations
                  ?.find(pos)
                  .find(deco => regex.exec(deco?.spec?.key as string) !== null)

                if (decoration) {
                  const key = decoration.spec.key
                  handleBackspaceDelete(
                    view,
                    decorations as DecorationSet,
                    selection,
                    key,
                    pos
                  )
                  return false
                }
              }
              return false
            },
          },
        }),
      ]
    },
  })

/**
 * Handles the backspace or delete key press event for removing custom placeholders.
 *
 * @param view - The editor view.
 * @param decorations - The decoration set.
 * @param selection - The current selection.
 * @param key - The key that triggered the event.
 */
function handleBackspaceDelete(
  view: EditorView,
  decorations: DecorationSet,
  selection: Selection,
  key: string,
  pos: number
) {
  const { state, dispatch } = view
  const { tr } = state

  const decoration = decorations.find(pos, pos).find(deco => {
    return (
      deco?.inline &&
      (key === `widget-${deco.spec.token}` || key === deco.spec.key)
    )
  })

  if (decoration && pos >= decoration.to) {
    const from = decoration.from
    const to = decoration.to
    const token = decoration.spec.token
    const textToInsert = token.slice(0, token.length - 1)
    tr.insertText(textToInsert, from, to)
    tr.setSelection(TextSelection.near(tr.doc.resolve(to - 1)))

    dispatch(tr)
    view.focus()
  }
}
