import i18n from '../../locales'

export interface PaymentScheduleItem {
  value: number
  dueDate: string | undefined
  _id?: string
  status?: PaymentScheduleStatus
}
export interface PaymentSchedule {
  dueDate: string | undefined
  paymentDueOn: PaymentDueOn
  billingScheduleType: BillingScheduleType
  billingSchedule: {
    interval: number
    unit: PaymentScheduleRegularUnits
  }
  type: PaymentScheduleType
  schedules: PaymentScheduleItem[]
  autoPayment?: AutoPaymentDetails
}

export interface AutoPaymentDetails {
  enable: boolean
  type?: AutoPaymentType
  paymentMethodId?: string
  customerId?: string
  paymentProvider?: PaymentProvider
  card?: Card
  usBankAccount?: USBankAccount
}

export enum AutoPaymentType {
  NEW_CARD = 'new_card',
  SAVED_CARD = 'saved_card',
  CUSTOMER_CARD = 'customer_card',
}

export enum PaymentScheduleType {
  PERCENTAGE = 'percentage',
  FIXED = 'fixed',
}

export enum PaymentDueOn {
  UPON_DOCUMENT_COMPLETE = 'upon_document_complete',
  CUSTOM_DATE = 'custom_date',
}

export enum BillingScheduleType {
  REGULAR = 'regular',
  CUSTOM_DATE = 'custom_date',
}

export enum PaymentScheduleStatus {
  PENDING = 'pending',
  PAID = 'paid',
}

export enum PaymentScheduleRegularUnits {
  YEARS = 'years',
  MONTHS = 'months',
  WEEKS = 'weeks',
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
}

export const getPaymentScheduleRegularUnits = (isPlural = true) => {
  return {
    REGULAR_UNITS: [
      {
        value: PaymentScheduleRegularUnits.DAYS,
        label: isPlural
          ? i18n.global.t('schedulingOptions.intervalType.days')
          : i18n.global.t('paymentSchedule.intervalType.day'),
      },
      {
        value: PaymentScheduleRegularUnits.WEEKS,
        label: isPlural
          ? i18n.global.t('schedulingOptions.intervalType.weeks')
          : i18n.global.t('paymentSchedule.intervalType.week'),
      },
      {
        value: PaymentScheduleRegularUnits.MONTHS,
        label: isPlural
          ? i18n.global.t('schedulingOptions.intervalType.months')
          : i18n.global.t('paymentSchedule.intervalType.month'),
      },
      {
        value: PaymentScheduleRegularUnits.YEARS,
        label: isPlural
          ? i18n.global.t('schedulingOptions.intervalType.years')
          : i18n.global.t('paymentSchedule.intervalType.year'),
      },
    ],
  }
}

export interface PaymentProvider {
  type: PaymentProvider
  connectedAccount?: any
}

export interface USBankAccount {
  bank_name: string
  last4: string
}

export interface Card {
  brand: string
  last4: string
}

export enum PaymentScheduleEvents {
  PAYMENT_SCHEDULE_UPDATED = 'payment-schedule-updated',
  PAYMENT_SCHEDULE_REMOVED = 'payment-schedule-removed',
}
