<script setup lang="ts">
import { config } from '../../../config'
import { Pencil01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UIButton } from '@gohighlevel/ghl-ui'
import { computed, ref } from 'vue'
import { EmailTemplateEditEvents } from '../../../types'

const emits = defineEmits([EmailTemplateEditEvents.EDIT_EMAIL_TEMPLATE_IN_APP])

const props = defineProps({
  templateId: {
    type: String,
    required: true,
  },
  locationId: {
    type: String,
    required: true,
  },
  templatePreviewBaseUrl: {
    type: String,
    required: false,
  },
})

const isHovered = ref(false)
const templatePreviewUrl = computed(() => {
  return `${
    props.templatePreviewBaseUrl || config.EMAIL_SERVICE_URL
  }/builder/preview/${props.locationId}/${props.templateId}`
})

function openEmailTemplateBuilder() {
  emits(EmailTemplateEditEvents.EDIT_EMAIL_TEMPLATE_IN_APP, {
    templateId: props.templateId,
    locationId: props.locationId,
    allowSavingNewTemplate: false,
  })
}
</script>

<template>
  <div class="w-full flex justify-between space-x-4 py-2 height-50">
    <div
      class="relative border p-2 rounded h-[180px] w-1/3 overflow-hidden"
      style="height: 180px; align-items: stretch; overflow: hidden"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
      :class="isHovered ? 'bg-black' : ''"
    >
      <div
        class="z-10 cursor-pointer flex justify-center items-center transition-opacity"
        :class="isHovered ? 'opacity-100' : 'opacity-0'"
        style="
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.1);
        "
      >
        <UIButton
          id="edit-email-template"
          size="small"
          @click="openEmailTemplateBuilder"
          class="z-20"
        >
          <Pencil01Icon class="text-black h-6 w-6" />
          <span class="ml-2 text-black">{{ $t('common.edit') }}</span>
        </UIButton>
      </div>
      <div class="thumbnail-container h-full">
        <div class="thumbnail">
          <iframe
            :src="templatePreviewUrl"
            frameborder="0"
            class="h-full w-full pointer-events-none"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.thumbnail-container {
  width: calc(1440px * 0.25);
  height: calc(900px * 0.25);
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.thumbnail {
  position: relative;
  transform: scale(0.25);
  transform-origin: 0 0;
  -moz-transform: scale(0.25);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.25);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.25);
  -webkit-transform-origin: 0 0;
}

.thumbnail iframe {
  width: 700px;
  height: 900px;
}
</style>
