<script lang="ts" setup>
import { IPage } from '../../types'
import { PropType, computed } from 'vue'
import { ref, onMounted } from 'vue'
import html2canvas from 'html2canvas'
import { LoadingSVG } from '../../assets'
import { usePagePreview } from '../../composition'

const props = defineProps({
  page: {
    type: Object as PropType<IPage>,
    default: () => ({}),
  },
  pageLength: {
    type: Number,
    default: 0,
  },
  isIframe: {
    type: Boolean,
    default: false,
  },
})

const { setThumbnail, getThumbnail } = usePagePreview()
const selectedDivId = computed(() => props.page?.id)
const canvasRef = ref(null)
const iframeRef = ref<HTMLIFrameElement | null>(null)
const isLoading = ref(true)
const scale = computed(() => {
  if (props.pageLength) {
    if (props.pageLength <= 10) {
      return 0.4
    } else if (props.pageLength <= 20) {
      return 0.3
    } else if (props.pageLength <= 30) {
      return 0.3
    } else if (props.pageLength <= 40) {
      return 0.2
    } else {
      return 0.2
    }
  } else {
    return 0.1
  }
})

const renderThumbnail = async (id: string, isForce = false) => {
  const can = document.getElementById(
    `canvas-preview-${id}`
  ) as HTMLCanvasElement
  const iframe = document.getElementById(
    `iframe-preview-${id}`
  ) as HTMLIFrameElement
  const element = document.getElementById(`page-${id}`)
  if (!element) return
  const previewThumbnail = getThumbnail(id)
  if (previewThumbnail && !isForce) {
    const context = can?.getContext('2d', {
      willReadFrequently: true,
      desynchronized: true,
    })
    if (context) {
      context.drawImage(previewThumbnail, 0, 0, 300, 208)
    }
    isLoading.value = false
  }

  try {
    if (!previewThumbnail && !isForce) isLoading.value = true
    if (props.isIframe && iframe) {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document
      if (!iframeDoc) return
      iframeDoc.open()
      iframeDoc.write(
        '<html><head><style>body { margin: 0; overflow: hidden; }</style></head><body>' +
          element.outerHTML +
          '</body></html>'
      )
      iframeDoc.close()
      const iframeBody = iframeDoc.body
      iframeBody.style.transform = 'scale(0.5)'
      iframeBody.style.transformOrigin = 'top left'
      iframeBody.style.width = '200%'
      iframeBody.style.height = 'auto'
    } else if (element && can) {
      const context = can?.getContext('2d', {
        willReadFrequently: true,
        desynchronized: true,
      })
      if (context) {
        const canvas = (await html2canvas(element, {
          scale: scale.value,
          useCORS: false,
          allowTaint: true,
          logging: false,
        })) as HTMLCanvasElement
        if (canvas) {
          context.clearRect(0, 0, 300, 208)
          context.drawImage(canvas, 0, 0, 300, 208)
          setThumbnail(id, canvas)
        }
      }
    }
    isLoading.value = false
  } catch (error) {
    console.error('Error rendering canvas:', error)
    isLoading.value = false
  }
}

const observeCanvas = (id: string) => {
  const canvasElement = document.getElementById(
    `canvas-preview-${id}`
  ) as HTMLCanvasElement
  const iframeElement = document.getElementById(
    `iframe-preview-${id}`
  ) as HTMLIFrameElement
  const observer = new IntersectionObserver(
    (entries, observerInstance) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          renderThumbnail(id)
          observerInstance.unobserve(entry.target)
          observerInstance.disconnect()
        }
      })
    },
    { threshold: 0.2 }
  )
  if (canvasElement) {
    observer.observe(canvasElement)
  }
  if (iframeElement) {
    observer.observe(iframeElement)
  }
}

onMounted(() => {
  observeCanvas(selectedDivId.value)
})
</script>

<template>
  <div v-if="isIframe">
    <div>
      <iframe
        ref="iframeRef"
        :id="`iframe-preview-${selectedDivId}`"
        class="h-full w-full pointer-events-none"
        frameborder="0"
      ></iframe>
    </div>
  </div>
  <div v-else>
    <div
      v-if="isLoading"
      class="flex justify-center items-center h-full loading-overlay"
    >
      <LoadingSVG class="spinner" />
    </div>
    <canvas
      :id="`canvas-preview-${selectedDivId}`"
      ref="canvasRef"
      class="w-full h-52"
    >
    </canvas>
  </div>
</template>

<style scoped>
.loading-overlay {
  background: rgba(255, 255, 255, 0.6);
}
.preview-container {
  display: flex;
  height: 100vh;
}
.preview {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem;
}
.canvas-preview {
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.spinner {
  animation: spin 1s linear infinite;
}
</style>
