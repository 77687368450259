import { useCurrency, useLineItems } from '@/composition'
import {
  DynamicEditableField,
  DynamicEditableFieldProps,
  DynamicTableCellConfig,
  ELEMENTS_META,
  IntervalType,
  InvoiceType,
  LineItem,
  PriceTypeEnum,
  V1_DynamicEditableField as ShowEditTableRow,
} from '@gohighlevel/ghl-proposals-common'
import { UITextSmRegular, UITextXsRegular } from '@gohighlevel/ghl-ui'
import { DefineComponent, h, ref } from 'vue'

import { useElementOption } from '@/composable/elementOptions.ts'
import { useActionBarStore } from '@/store/actionBar'
import { useBuilderStore } from '@/store/builder'
import { useProductListStore } from '@/store/productListStore'
import i18n from '@gohighlevel/ghl-proposals-common/src/locales'

interface UpdateOptions {
  key: keyof LineItem
  value: string | number
}
export const updateTableData = (productId: string) => {
  const { productLineOptions } = useLineItems(productId)
  const { updateOption } = useElementOption()
  const productListStore = useProductListStore()
  return (
    options: UpdateOptions | UpdateOptions[],
    id: string,
    index?: number
  ) => {
    let obj = {}
    if (Array.isArray(options)) {
      obj = options.reduce((acc, cur, idx) => {
        if (index !== undefined && index !== idx) {
          acc[cur.key] = cur.value
        } else {
          acc[cur.key] = cur.value
        }

        return acc
      }, {} as Record<keyof LineItem, any>)
    } else {
      obj = options
    }
    updateOption(
      ELEMENTS_META.LINE_ITEMS,
      productLineOptions.value.lineItems.map(item => {
        if (item.key === id) {
          const updatedItem = {
            ...item,
            ...obj,
          }
          return {
            ...updatedItem,
            subtotal: updatedItem.qty * updatedItem.price,
          }
        }
        return item
      }),
      productId
    )
    productListStore.updateActiveRow(obj)
  }
}

const translationMap = {
  [IntervalType.MONTHLY]: 'common.repeatsMonthly',
  [IntervalType.WEEKLY]: 'common.repeatsWeekly',
  [IntervalType.DAILY]: 'common.repeatsDaily',
  [IntervalType.YEARLY]: 'common.repeatsYearly',
  [IntervalType.MINUTELY]: 'common.repeatsMinutely',
  [IntervalType.HOURLY]: 'common.repeatsHourly',
}

export const getProductListColumns = (
  elementId: string,
  elementVersion = 1
): DynamicTableCellConfig[] => {
  const updateValue = updateTableData(elementId)
  const { formatCurrency, getCurrencySymbol } = useCurrency()
  const builderStore = useBuilderStore()
  const actionBarStore = useActionBarStore()
  const productListStore = useProductListStore()
  const nameEditMap = ref({} as Record<string, boolean>)

  const getPriceHelperText = (lineItem: LineItem) => {
    if (
      lineItem.priceType === InvoiceType.RECURRING &&
      lineItem.isSetupFeeItem
    ) {
      return i18n.global.t('common.onetime')
    } else if (
      lineItem.priceType === InvoiceType.RECURRING &&
      productListStore.invoiceType === PriceTypeEnum.RECURRING
    ) {
      const translationInterval =
        translationMap[productListStore?.schedule?.intervalType]
      return translationInterval ? i18n.global.t(translationInterval) : ''
    } else {
      return i18n.global.t('common.onetime')
    }
  }

  if (elementVersion < 3) {
    return [
      {
        title: i18n.global.t('common.item'),
        key: 'item',
        width: 200,
        columnClass: 'px-0' + (elementVersion > 1 ? '' : ' pl-4'),
        renderCell(row: any, { index }) {
          return h(ShowEditTableRow as unknown as DefineComponent, {
            value: row.name,
            name: 'name',
            editable: builderStore.isDocumentEditable,
            onUpdateValue(v: any) {
              updateValue([{ key: 'name', value: v }], row.key, index)
            },
            onInputFocus(v: boolean) {
              nameEditMap.value[row.key] = v
              if (v) {
                builderStore.setActiveElementId(elementId)
                productListStore?.setActiveRow(row, elementId)
                actionBarStore.setPaymentSettings(true)
              } else {
                productListStore?.setActiveRow(null, null)
              }
            },
            id: elementId,
          })
        },
      },
      {
        title: i18n.global.t('common.price'),
        key: 'priceText',
        width: 150,
        renderCell(row: any) {
          return h('div', {}, [
            h(UITextSmRegular, {
              class: 'text-left',
              innerHTML: row.priceText,
              align: 'left',
            }),
            h(UITextXsRegular, {
              class: 'text-left text-gray-400 mt-1',
              innerHTML: getPriceHelperText(row),
              align: 'left',
            }),
          ])
        },
      },
      {
        title: i18n.global.t('common.quantity'),
        key: 'qty',
        columnClass: 'px-0',
        renderCell(row: any) {
          return h(ShowEditTableRow as unknown as DefineComponent, {
            isNumber: true,
            value: row.qty,
            name: 'qty',
            editable: builderStore.isDocumentEditable,
            onUpdateValue(v: any) {
              updateValue(
                [
                  {
                    key: 'qty',
                    value: Number(v),
                  },
                  {
                    key: 'maxQty',
                    value: Number(v),
                  },
                ],
                row.key
              )
            },
            onInputFocus(v: boolean) {
              nameEditMap.value[row.key] = v
              if (v) {
                builderStore.setActiveElementId(elementId)
                productListStore?.setActiveRow(row, elementId)
                actionBarStore.setPaymentSettings(true)
              } else {
                productListStore?.setActiveRow(null, null)
              }
            },
            validateOn: ['change', 'input', 'blur', 'focus'],
            validator(inputValue: number) {
              const val = Number(inputValue)
              if (!Number.isSafeInteger(val) || val < 0) {
                return new Error(
                  i18n.global.t('validations.invalid.negativeOrFraction')
                )
              }
              return
            },
          })
        },
      },
      {
        title: i18n.global.t('common.subtotal'),
        key: 'subtotal',
        align: 'right',
        renderCell(row: any) {
          return h('div', {
            class: 'text-right',
            innerHTML: formatCurrency(
              row.subtotal,
              getCurrencySymbol(row.currency || productListStore?.currency)
            ),
            align: 'right',
          })
        },
      },
    ]
  }
  return [
    {
      title: i18n.global.t('common.item'),
      key: 'item',
      width: 250,
      columnClass: 'px-0' + (elementVersion > 1 ? '' : ' pl-4'),
      renderCell(row: any, { index, rowActive }) {
        return h('div', {}, [
          h(
            DynamicEditableField as DefineComponent<DynamicEditableFieldProps>,
            {
              value: row.name,
              name: 'name',
              editable: builderStore.isDocumentEditable,
              onUpdateValue(v: any) {
                updateValue([{ key: 'name', value: v }], row.key, index)
              },
              onInputFocus(v: boolean) {
                if (v) {
                  builderStore.setActiveElementId(elementId)
                  productListStore?.setActiveRow(row, elementId)
                  actionBarStore.setPaymentSettings(true)
                } else {
                  productListStore?.setActiveRow(null, null)
                }
              },
              id: elementId,
            }
          ),
          h(
            DynamicEditableField as DefineComponent<DynamicEditableFieldProps>,
            {
              richText: row.description,
              name: 'description',
              editable: builderStore.isDocumentEditable,
              isRichText: true,
              rowActive: rowActive,
              onUpdateValue(v: any) {
                updateValue([{ key: 'description', value: v }], row.key, index)
              },
              onInputFocus(v: boolean) {
                nameEditMap.value[row.key] = v
                if (v) {
                  builderStore.setActiveElementId(elementId)
                  productListStore?.setActiveRow(row, elementId)
                  actionBarStore.setPaymentSettings(true)
                } else {
                  productListStore?.setActiveRow(null, null)
                }
              },
              id: elementId,
            }
          ),
        ])
      },
    },
    {
      title: i18n.global.t('common.price'),
      key: 'priceText',
      width: 100,
      columnClass: 'pt-6',
      renderCell(row: any) {
        return h('div', {}, [
          h(UITextSmRegular, {
            class: 'text-left',
            innerHTML: row.priceText,
            align: 'left',
          }),
          h(UITextXsRegular, {
            class: 'text-left text-gray-400  mt-1',
            innerHTML: getPriceHelperText(row),
            align: 'left',
          }),
        ])
      },
    },
    {
      title: i18n.global.t('common.quantity'),
      key: 'qty',
      columnClass: 'px-0',
      renderCell(row: any) {
        return h(
          DynamicEditableField as DefineComponent<DynamicEditableFieldProps>,
          {
            isNumber: true,
            value: row.qty,
            name: 'qty',
            editable: builderStore.isDocumentEditable,
            onUpdateValue(v: any) {
              updateValue(
                [
                  {
                    key: 'qty',
                    value: Number(v),
                  },
                  {
                    key: 'maxQty',
                    value: Number(v),
                  },
                ],
                row.key
              )
            },
            onInputFocus(v: boolean) {
              nameEditMap.value[row.key] = v
              if (v) {
                builderStore.setActiveElementId(elementId)
                productListStore?.setActiveRow(row, elementId)
                actionBarStore.setPaymentSettings(true)
              } else {
                productListStore?.setActiveRow(null, null)
              }
            },
            validateOn: ['change', 'input', 'blur', 'focus'],
            validator(inputValue: number) {
              const val = Number(inputValue)
              if (!Number.isSafeInteger(val) || val < 0) {
                return new Error(
                  i18n.global.t('validations.invalid.negativeOrFraction')
                )
              }
              return null
            },
          }
        )
      },
    },
    {
      title: i18n.global.t('common.subtotal'),
      key: 'subtotal',
      align: 'right',
      columnClass: 'pt-6',
      renderCell(row: any) {
        return h('div', {
          class: 'text-right',
          innerHTML: formatCurrency(
            row.subtotal,
            getCurrencySymbol(row.currency || productListStore?.currency)
          ),
          align: 'right',
        })
      },
    },
  ]
}
