<script lang="ts" setup>
import {
  UIFormItem,
  UIButton,
  UIInputGroup,
  UIInput,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import { Tag01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { FloatingCustomSelect } from '../CustomSelectTrigger'
import { useCustomValues } from '../../composition'
import { ref } from 'vue'

const props = defineProps({
  id: { type: String, required: true },
  label: { type: String, required: true },
  value: { type: String, required: true },
  disabled: { type: Boolean, default: false },
  withCustomValueBtn: { type: Boolean, default: true },
  showFeedback: { type: Boolean, default: true },
})
const emits = defineEmits(['update:value'])
const { customMenuOptionsWithoutOpp } = useCustomValues()

const onChange = (val: string) => {
  emits('update:value', val)
}
const onSelect = (val: string) => {
  emits('update:value', (props.value || '') + val)
}

const show = ref(false)
const showCustomValues = (e: MouseEvent) => {
  e.stopPropagation()
  show.value = !show.value
}
const hideCustomValues = () => {
  show.value = false
}
</script>

<template>
  <div class="w-full" :key="id">
    <UIFormItem :label="props.label" :showFeedback="props.showFeedback">
      <UIInputGroup>
        <UIInput
          :id="'input-field-' + props.id"
          :model-value="value"
          @update:model-value="onChange"
          :disabled="disabled"
        />
        <FloatingCustomSelect
          v-if="props.withCustomValueBtn"
          :customMenus="customMenuOptionsWithoutOpp"
          @on-select="onSelect"
          :show="show"
          @onClickOutSide="hideCustomValues"
        >
          <template #trigger>
            <UIButton
              :disabled="disabled"
              :id="'custom-values-trigger-' + id"
              size="medium"
              class="custom-menu__button mx-1"
              :text="false"
            >
              <UITooltip>
                <template #trigger>
                  <Tag01Icon class="h-4 w-4" @click="showCustomValues" />
                </template>
                {{ $t('common.customValues') }}
              </UITooltip>
            </UIButton>
          </template>
        </FloatingCustomSelect>
      </UIInputGroup>
    </UIFormItem>
  </div>
</template>
