import { setupSentry } from '@gohighlevel/ghl-proposals-common'
import i18n from '@gohighlevel/ghl-proposals-common/src/locales'
import '@gohighlevel/ghl-ui/dist/style.css'
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query'
import mitt from 'mitt'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import './assets/builder.scss'
import './assets/elements.scss'
import { router } from './router'
import './style.css'
const emitter = mitt()
const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(i18n)
app.use(VueQueryPlugin, {
  queryClient: new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1, // Retry once if the query fails
        refetchOnWindowFocus: false, // Disable refetching on window focus
      },
    },
  }),
})
app.config.globalProperties.emitter = emitter
app.use(router)
app.mount('#app')
setupSentry(app, router, process.env.NODE_ENV)
