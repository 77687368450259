import { config } from '../config'
import { requests } from '../utils'

export interface EmailTemplateInfo {
  builderVersion: string
  downloadUrl: string
  id: string
  name: string
  updatedBy: string
  _id: string
}

export const EmailTemplateService = {
  cloneDefaultEmailTemplate: (params: any) => {
    return requests(config.PROPOSAL_SERVICE_URL).post(
      '/settings/clone-email-template',
      params
    )
  },
  getEmailTemplates: (altId: string, templateId: string) =>
    requests(config.EMAIL_SERVICE_URL).get(
      `/builder/all?locationId=${altId}&id=${templateId}`
    ),
  getAllEmailTemplates: (params: { locationId: string }) =>
    requests(config.EMAIL_BUILDER_SERVICE_URL).get<EmailTemplateInfo[]>(
      '/builder/all',
      {
        params,
      }
    ),
}
