import {
  CustomNotificationService,
  IProposalCustomerCustomNotificationSettings,
} from '@/service/EmailSettingService'

import { pick } from 'lodash'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useNotify } from './useNotify'

export const defaultCustomNotificationSettings = {
  documentReceivedTemplateId: 'default',
  documentSignedTemplateId: 'default',
  documentSignedTemplateIdForTeam: 'default',
  enableDocumentReceiveNotification: true,
  enableDocumentSignedNotification: true,
  enableDocumentSignedForTeamNotification: true,
  enableAutoSentInvoice: false,
  enableDirectPayment: false,
  documentSignedSubject: '[{{location.name}}] {{document.name}} signed',
  documentReceivedSubject: '[{{location.name}}] {{document.name}} received',
  documentSignedForTeamSubject: '[{{location.name}}] {{document.name}} signed',
  documentSignedEmail: '',
  documentSignedEmailFromName: '',
  documentReceivedEmail: '',
  documentReceivedEmailFromName: '',
  overrideSignedEmailSender: false,
  overrideReceivedEmailSender: false,
  overrideSignedForTeamEmailSender: false,
  documentSignedForTeamEmail: '',
  documentSignedForTeamEmailFromName: '',
} as IProposalCustomerCustomNotificationSettings

const loading = ref(false)
const error = ref(null)
const notificationSetting = ref(defaultCustomNotificationSettings)

export const useCustomNotificationSettings = () => {
  const route = useRoute()
  const locationId = route.params.locationId as string
  const { handleError, handleInfo } = useNotify()

  const sanitizeSettingsData = (data: Record<string, any>) => {
    return pick(data, [
      'documentReceivedTemplateId',
      'documentSignedTemplateId',
      'documentSignedTemplateIdForTeam',
      'enableDocumentReceiveNotification',
      'enableDocumentSignedForTeamNotification',
      'enableDocumentSignedNotification',
      'enableAutoSentInvoice',
      'documentReceivedSubject',
      'documentSignedSubject',
      'documentSignedForTeamSubject',
      'documentSignedEmail',
      'documentSignedEmailFrom',
      'documentReceivedEmail',
      'documentReceivedEmailFromName',
      'overrideSignedEmailSender',
      'overrideReceivedEmailSender',
      'overrideSignedForTeamEmailSender',
      'documentSignedForTeamEmail',
      'documentSignedForTeamEmailFromName',
      'documentSignedEmailFromName',
      'enableAutoSentInvoice',
      'enableDirectPayment',
      '_id',
    ])
  }
  const fetchCustomNotificationSetting = async () => {
    try {
      loading.value = true
      const { data } =
        await CustomNotificationService.getCustomNotificationSetting({
          locationId,
        })
      notificationSetting.value = sanitizeSettingsData(
        data
      ) as unknown as IProposalCustomerCustomNotificationSettings
    } catch (err) {
      console.error(err)
      error.value = err
    } finally {
      loading.value = false
    }
  }

  const updateCustomNotificationSettings = async (
    params: Partial<IProposalCustomerCustomNotificationSettings>
  ) => {
    try {
      loading.value = true
      const { data } =
        await CustomNotificationService.updateCustomNotificationSetting({
          locationId,
          ...notificationSetting.value,
          ...params,
        })
      notificationSetting.value = sanitizeSettingsData(
        data
      ) as unknown as IProposalCustomerCustomNotificationSettings
      handleInfo('Successfully saved')
    } catch (err) {
      console.error(err)
      error.value = err
      handleError(err)
    } finally {
      loading.value = false
    }
  }
  return {
    loading,
    error,
    notificationSetting,
    fetchCustomNotificationSetting,
    updateCustomNotificationSettings,
  }
}
